const moment = require('moment');
const axios = require('axios');
// get toll charges
export const getTollCharges = async(polyline, vehType, grand) => {
    try {
        console.log(polyline, vehType, grand, "polyline", vehType, grand)
        return axios.post('https://apis.tollguru.com/toll/v2/complete-polyline-from-mapping-service', {
                vehicle: {
                    "type": vehType
                },
                polyline: polyline
            }, {
                headers: {
                    "x-api-key": "MRpFr4R3QGJgfrmtq3JpjP4Qhb4Dfrhf",
                    "Content-Type": "application/json"
                }
            })
            .then(function(response) {
                let tollCharges = response.data.route.costs.minimumTollCost;
                console.log(tollCharges, "tollCharges")
                console.log(grand, "grand before")
                if (tollCharges > 0) {
                    grand = grand + tollCharges
                }
                else
                {
                    grand = grand
                }

                console.log(grand, "grand after")
                return grand
            })
            .catch(function(error) {
                    console.log(error, "error");
                    return grand
                }


            )
    } catch (error) {
        console.log(error, "error")
        return grand;
    }
};


function compareTimesForStart(time1, time2) {
    const format = 'h:mm:ss A'; // Specify the format of the time

    const momentCurrentTime = moment(time1, format)
    const momentStartTime = moment(time2, format);
    if (momentStartTime.format('A') === 'PM' && momentCurrentTime.format('A') === 'AM') {
        momentCurrentTime.add(+1, "days");
        momentStartTime.add(-1, 'days')
    }
    console.log(momentStartTime, "momentStartTime")
    console.log(momentCurrentTime, "momentCurrentTime")
    if (momentCurrentTime.isBefore(momentStartTime)) {
        return false
    } else if (momentCurrentTime.isAfter(momentStartTime)) {
        return true
    } else {
        return false
    }
}

function compareTimesForEnd(time1, time2) {
    const format = 'h:mm:ss A'; // Specify the format of the time

    const momentTime1 = moment(time1, format);
    const momentTime2 = moment(time2, format);

    if (momentTime1.isBefore(momentTime2)) {
        return true
    } else if (momentTime1.isAfter(momentTime2)) {
        return false
    } else {
        return true
    }
}

export const FareCalculator = (distance, time, rateDetails, instructionData, decimal) => {
    console.log(distance, time, rateDetails, instructionData, decimal, "distance, time, rateDetails, instructionData, decimal")
    let baseCalculated = (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance)) + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));
    if (rateDetails.base_fare > 0) {
        baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    if (instructionData && instructionData.parcelTypeSelected) {
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if (instructionData && instructionData.optionSelected) {
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }
    let total = baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);
    let convenienceFee = 0;
    if (rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat') {
        convenienceFee = rateDetails.convenience_fees;
    } else {
        convenienceFee = (total * parseFloat(rateDetails.convenience_fees) / 100);
    }

    let grand = total + convenienceFee;

    if (rateDetails.night_start_time && rateDetails.night_end_time) {
        let currentTime = new Date().toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
        }).split(",")[1].trim();
        let startTime = rateDetails.night_start_time;
        let endTime = rateDetails.night_end_time;
        if (endTime.split(" ")[1] === "AM") {
            let hour = endTime.split(":")[0];
            let minute = endTime.split(":")[1];
            let second = endTime.split(":")[2];
            let newHour = parseInt(hour) + 12;
            endTime = `${newHour}:${minute}:${second}`;
        }
        if (compareTimesForStart(currentTime, startTime) && compareTimesForEnd(currentTime, endTime)) {
            // apply night charges
            let nightCharges = (grand * rateDetails.nighte_rate_percentage / 100);
            console.log(nightCharges, "nightCharges")
            grand = grand + nightCharges;

        }

    }

    return {
        totalCost: parseFloat(total.toFixed(decimal)),
        grandTotal: parseFloat(grand.toFixed(decimal)),
        convenience_fees: parseFloat(convenienceFee.toFixed(decimal))
    }

}