import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Faq(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>FAQ!</h4>
            <h2>
              Feel your journey <br /> with <span>GoBabyGo!</span>
            </h2>
            <p>
              All your doubts, answered here! {' '}
            </p>
          </div>
        </div>
      </section>

      <div style={{ margin: 20 }}>



 
 <div className={classes.container} style={{ padding: '5% 0' }}>
 <h2>FAQ</h2>
<div class="row">
<div class="col-lg-12 sm-padding">
<div class="accordion faq-accordion" id="faq-accordion">
<div class="accordion-item">
<h2 class="accordion-header" id="headingOne">
<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Why should we choose Gobabygo?</button>
</h2>
<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>As we work on a 0% commission policy and provide fast and safer services, you can choose us over other cab service providers. Some of the other reasons are that, we take pride in our commitment to punctuality, safety, affordability, convenience, variety of options, excellent customer service, loyalty benefits and community focus.</p>
</div>
</div>
</div>

<div class="accordion-item">
<h2 class="accordion-header" id="headingTwo">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What is Gobabygo goal? </button>
</h2>
<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>GoBabyGo Cab’s primary goal is revolutionizing the way people experience transportation. Our mission is to provide safe, reliable, and convenient rides that cater to the diverse needs of travelers while maintaining a strong commitment to customer satisfaction and community engagement.</p>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="headingThree">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Does Gobabygo only provide cab services? </button>
</h2>
<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>GoBabyGo provides a variety of services in the transport industry. We offer services like ridesharing, package delivery, bike and scooter taxis, car and bike rentals, and specialized services.</p>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="headingFour">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"> What policies does Gobabygo follow for their customers? </button>
</h2>
<div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>GoBabyGo is committed to providing a seamless and enjoyable experience for its customers. To achieve this goal, we have implemented several customer-centric policies that prioritize safety, convenience, satisfaction, transparent pricing, convenient booking, reliability, ease of cancellation and refund, quality customer support team, accessibility, data privacy, and feedback mechanisms.</p>
</div>
</div>
</div>


<div class="accordion-item">
<h2 class="accordion-header" id="headingFive">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Can I book a cab from Gobabygo without paying online? </button>
</h2>
<div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>Yes, GoBabyGo offers the flexibility to book a cab without paying online. We understand that customers may have their own preferred payment methods. When you book a cab with GoBabyGo, you can typically choose to pay in cash or use other accepted payment options directly to the driver at the end of the ride. This payment flexibility is a great feature to those who prefer to settle their transportation expenses in person or may not have access to online payment methods.</p>
</div>
</div>
</div>


<div class="accordion-item">
<h2 class="accordion-header" id="headingSix">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"> Does Gobabygo accept Paytm? </button>
</h2>
<div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>Yes, GoBabyGo is known for its commitment to providing a wide range of payment options to cater to the diverse needs of its customers.</p>
</div>
</div>
</div>


<div class="accordion-item">
<h2 class="accordion-header" id="headingSeven">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"> How long does it take to confirm a ride? </button>
</h2>
<div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>The confirmation time for a ride with GoBabyGo is typically very quick and efficient. Thanks to our advanced technology and user-friendly booking process, you can expect your ride to be confirmed within just a few moments after submitting your booking request.</p>
</div>
</div>
</div>


<div class="accordion-item">
<h2 class="accordion-header" id="headingEight">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight"> How does Gobabygo ensure the safety of the riders? </button>
</h2>
<div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>GoBabyGo ensures riders’ safety by applying policies that are Driver Vetting, Vehicle Inspection, GPS Tracking, Driver Identification, Emergency Services, Rating System, Customer Support, Insurance Coverage, COVID-19 Safety Measures, and Community Engagement.</p>
</div>
</div>
</div>


<div class="accordion-item">
<h2 class="accordion-header" id="headingNine">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine"> What does Gobabygo do? </button>
</h2>
<div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#faq-accordion">
<div class="accordion-body">
 <p style={{ color: '#666666', padding: 0 }}>GoBabyGo Cabs provides safe and convenient transportation services. We offer a range of vehicle options, prioritize passenger safety through driver vetting and vehicle inspections, and typically feature an easy-to-use app for booking and tracking rides. GoBabyGo focuses on delivering reliable and enjoyable travel experiences for customers.</p>
</div>
</div>
</div>

 

</div>
</div>
</div>
</div>
 
      </div>

      <Footer />
    </div>
  );
}
