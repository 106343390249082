import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

import customer1 from "../assets/img/customer1.png"
import customer2 from "../assets/img/customer2.png"
import customer3 from "../assets/img/customer3.png"
import customer4 from "../assets/img/customer4.png"
import driver1 from "../assets/img/driver1.png"
import driver2 from "../assets/img/driver2.png"
import driver3 from "../assets/img/driver3.jpg"

import Slider from 'react-slick';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api } from 'common';
import { useSelector, useDispatch } from "react-redux";



function Testimonials() {
    const dispatch = useDispatch();
    const [customers, setCustomers] = useState([]);
    const [Drivers, setDrivers] = useState([]);
    const [currentSection, setCurrentSection] = useState('customers'); // default is 'customers'
    const section = currentSection === 'customers' ? customers : Drivers;
    const feedBacks = useSelector(state => state.usersdata.feedBacks);
    const {
        fetchFeedbacks,
    } = api;
    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchFeedbacks());
        }, 5000);
        dispatch(fetchFeedbacks());
    }, [dispatch, fetchFeedbacks])

    useEffect(() => {
        if (feedBacks?.customerArr && feedBacks?.customerArr.length > 0) {
            setCustomers(feedBacks?.customerArr);
        } else {
            setCustomers([]);
        }
        if (feedBacks?.driverArr && feedBacks?.driverArr.length > 0) {
            setDrivers(feedBacks?.driverArr);
        } else {
            setDrivers([]);
        }
    }, [feedBacks]);
    const activeButtonStyle = {
        backgroundColor: '#f9c935',
        color: '#000000',
        border: '2px solid #f9c935',
        width: '200px'
    };

    const inactiveButtonStyle = {
        backgroundColor: 'transparent',
        color: '#000000',
        border: '2px solid rgba(0, 0, 0, 0.1)',
        width: '200px'
    };



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Box sx={{ width: '80%', padding: '2rem 0', margin: '4% auto' }}>
                <h2 style={{ fontSize: '42px', textAlign: 'center', paddingBottom: "2%" }}>Direct from the Core of</h2>
                <style jsx="true">{`
        .slick-prev:before, .slick-next:before {
            color: black;
        }

        .slick-dots li button:before {
            color: gray;
        }

        .slick-dots li.slick-active button:before {
            color: black;
        }
      `}</style>

                <Box display="flex" justifyContent="center" marginBottom={2}>

                    <Button style={currentSection === 'customers' ? activeButtonStyle : inactiveButtonStyle} onClick={() => setCurrentSection('customers')}>
                        Customers
                    </Button>
                    <Button style={currentSection === 'Drivers' ? activeButtonStyle : inactiveButtonStyle} onClick={() => setCurrentSection('Drivers')}>
                        Drivers
                    </Button>
                </Box>

                <Slider {...settings} style={{ padding: '4% 0' }}>
                    {section.map((item, index) => (

                        item.status == 'show' ?
                            <div key={index} style={{ textAlign: 'center', width: '70%' }}>
                                <img
                                    src={item.user.profile_image ? item.user.profile_image : 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'}
                                    alt={`choice-${index}`} width="100" height="100" style={{ margin: 'auto', borderRadius: '100%', objectFit: 'cover' }} />
                                <Typography variant="h6" style={{ marginTop: '20px', textAlign: 'center', fontSize: '24px' }}>
                                    {item.user.firstName} {item.user.lastName}
                                </Typography>
                                <Typography variant="body1" color="initial" style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>{item.comment}</Typography>
                            </div>
                            : null

                    ))}
                </Slider>
            </Box >
        </div>
    );
}

export default Testimonials;
