// ProfileModal.js
import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person'; // Import a placeholder user icon
import { Card, CardContent, Typography, Rating } from '@mui/material';

function DriverProfileModal({ open, onClose, profile,customerImage,customerName,rating }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
         {/* Close button */}
         <Button
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon />
        </Button>   
        {/* Profile information */}
        
              <Avatar
          alt={profile?.profile_image}
          src={profile?.profile_image || ''}
          sx={{
            width: 100,
            height: 100,
            margin: '0 auto 16px', // Center the avatar and add margin below it
          }}
        >
          {profile?.image ? null : <PersonIcon />} {/* Display placeholder icon if no image */}
        </Avatar>
        <h2 
        >{profile?.firstName} {profile?.lastName}</h2>
        <p>Email: {profile?.email}</p>
        <p>Phone No: {profile?.mobile}</p>
        <p>Date of Joining: {
          new Date(profile?.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          }</p>
        <p>Gender: {profile?.gender}</p>
        <p>DOB: {profile?.gender}</p>
        <Card>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={customerImage&&customerImage} alt={customerName&&customerName} />
          <Typography variant="h6" sx={{ marginLeft: 2 }}>
            {customerName && customerName}
          </Typography>
        </div>
        <Rating
          name="customer-rating"
          value={rating && rating}
          precision={0.5}
          readOnly
          size="large"
        />
      </CardContent>
    </Card>
      </Box>
    


    </Modal>
  );
}

export default DriverProfileModal;