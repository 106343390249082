import React, { useState, useEffect, useRef } from 'react';
import { downloadCsv } from '../common/sharedFunctions';
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';

import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { colors } from '../components/Theme/WebTheme';
import {
  Modal,
  Grid, Box,
  Typography
} from '@mui/material';
import Button from "components/CustomButtons/Button.js";
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import AlertDialog from '../components/AlertDialog';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import "./FeedBackStyle.css"
const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20,
    height: '10%'
  },
  submit1: {
    marginLeft: 30
  },
  submit: {
    backgroundColor: '#de7d1e',
  },
  submit5: {
    backgroundColor: '#0c5c6b',
  },
  submit3: {
    width: '100%',
    borderRadius: 3,
    marginTop: 2,
    padding: 4
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  submit4: {
    backgroundColor: 'red',
    width: '100%',
    borderRadius: 3,
    marginTop: 2,
    padding: 4
  }
}));

export default function CustomerFeedBack() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editFeedBack,
    fetchUsersOnce,
    updateLicenseImage,
    fetchFeedbacks,
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const feedBacks = useSelector(state => state.usersdata.feedBacks);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const classes = useStyles();
  const rootRef = useRef(null);

  useEffect(() => {
    dispatch(fetchFeedbacks());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (feedBacks?.customerArr && feedBacks?.customerArr.length > 0) {
      setData(feedBacks?.customerArr);
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [feedBacks, auth.profile]);

  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable: 'never', defaultSort: 'desc', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('lll') : null, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: "First Name", field: 'firstName', render: rowData => rowData.user.firstName, cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
    { title: t('last_name'), field: 'lastName', render: rowData => rowData.user.lastName, cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
    { title: "Rating", field: 'rating', cellStyle: { textAlign: 'left' } },
    { title: "Comments", field: 'comment', cellStyle: { textAlign: 'left' } },
    {
      title: t('status'), field: 'status', lookup: { "show": t('active'), "hide": t('inactive') },
      render: rowData => <Button onClick={() => {
        dispatch(editFeedBack(rowData.id, { status: rowData.status === 'show' ? 'hide' : 'show' }));
      }
      }
        style={{ backgroundColor: rowData.status === 'show' ? '#de7d1e' : '#0c5c6b' }} className={classes.submit3} >
        {rowData.status === 'show' ? t('active') : t('inactive')}
      </Button>


    },


  ];

  const [profileModal, setProfileModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [imageData, setImageData] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [open, setModal] = useState(false);
  const [editRowData, editRow] = useState(null);
  const [formData, setFormData] = useState({});
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const style = {
    height: "500px",
    overflowY: "scroll",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const onClose = () => {
    setModal(false);
  }
  const openModal = () => {
    setModal(true);
  }
  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit',
      onClick: (event, rowData) => {
        // Open the modal and pass the data to edit
        editRow(rowData);
        openModal();

      },
    },

  ];
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "name, value");
    editRowData[name] = value;
    editRow({ ...editRowData });


};
  const handleSave = async () => { 
    // remove id
    let id  = editRowData.id;
    delete editRowData.id;
    delete editRowData.tableData;
    delete editRowData.user;
    console.log(editRowData, "editRowData");
    dispatch(editFeedBack(id, editRowData));
    setModal(false);

  }
  return (
    !loaded.current ? <CircularLoading /> :
      <div>
        <MaterialTable
          title={t('riders')}
          columns={columns}
          style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E" }}
          data={data}
          onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          options={{
            exportCsv: (columns, data) => {
              let hArray = [];
              const headerRow = columns.map(col => {
                if (typeof col.title === 'object') {
                  return col.title.props.text;
                }
                hArray.push(col.field);
                return col.title;
              });
              const dataRows = data.map(({ tableData, ...row }) => {
                row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' ' + new Date(row.createdAt).toLocaleTimeString()
                let dArr = [];
                for (let i = 0; i < hArray.length; i++) {
                  dArr.push(row[hArray[i]]);
                }
                return Object.values(dArr);
              })
              const { exportDelimiter } = ",";
              const delimiter = exportDelimiter ? exportDelimiter : ",";
              const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
              const csvFileName = 'download.csv';
              downloadCsv(csvContent, csvFileName);
            },
            exportButton: {
              csv: settings.AllowCriticalEditsAdmin,
              pdf: false,
            },
            maxColumnSort: "all_columns",
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
            editable: {
              backgroundColor: colors.Header_Text,
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: 'Lucida Console", "Courier New", monospace'
            },
            headerStyle: {
              position: "sticky",
              top: "0px",
              backgroundColor: colors.Header_Text_back,
              color: '#fff',
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: 'Lucida Console", "Courier New", monospace'
            },
         
          }}
          localization={{
            body: {
              addTooltip: (t('add')),
              deleteTooltip: (t('delete')),
              editTooltip: (t('edit')),
              emptyDataSourceMessage: (
                (t('blank_message'))
              ),
              editRow: {
                deleteText: (t('delete_message')),
                cancelTooltip: (t('cancel')),
                saveTooltip: (t('save'))
              },
            },
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: (t('actions'))
            },
            pagination: {
              labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
              firstTooltip: (t('first_page_tooltip')),
              previousTooltip: (t('previous_page_tooltip')),
              nextTooltip: (t('next_page_tooltip')),
              lastTooltip: (t('last_page_tooltip'))
            },
          }}

          actions={actions}

        />

        <Modal open={open} onClose={onClose}>
          <Box sx={style}>
            <h2>Edit Feedback </h2>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "10px" }}
            >
           
                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="rating"
                      label="Rating"
                      value={editRowData?.rating}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
               
                  
                  <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Status</InputLabel>
                      <>
                      
                          <Select
                            name="status"
                            value={editRowData?.status}
                            onChange={handleInputChange}
                            displayEmpty

                          >
                            <MenuItem
                              value={'show'}>Active</MenuItem>
                            <MenuItem
                              value={"hide"}>In-Active</MenuItem>
                  
                          </Select>


                 
                      </>
                    </FormControl>

                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                       multiline
                       rows={6}
                      name="comment"
                      label="Comment"
                      value={editRowData?.comment}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                {/* <Grid mt={2} container spacing={2}>

                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="driverId"
                      label="Driver Id"
                      value={formData?.driverId}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={formData?.firstName}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={formData?.lastName}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="mobile"
                      label="Mobile Number"
                      value={formData?.mobile}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="email"
                      label="Email"
                      value={formData?.email}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <>
                        {
                          (formData?.gender) &&
                          <Select
                            name="gender"
                            value={formData?.gender}
                            onChange={handleInputChange}
                            displayEmpty

                          >
                            <MenuItem
                              value={'male'}>Male</MenuItem>
                            <MenuItem
                              value={"female"}>Female</MenuItem>
                            <MenuItem
                              value={"other"}>Other</MenuItem>
                          </Select>


                        }
                        {
                          !formData?.hasOwnProperty("gender") &&
                          <Select
                            name="gender"
                            value={formData?.gender}
                            onChange={handleInputChange}
                            displayEmpty

                          >
                            <MenuItem
                              value={'male'}>Male</MenuItem>
                            <MenuItem
                              value={"female"}>Female</MenuItem>
                            <MenuItem
                              value={"other"}>Other</MenuItem>
                             Add more options as needed 
                          </Select>
                        }
                      </>
                    </FormControl>

                  </Grid>
                  <Grid item xs={6}>

                    <TextField
                      name="dob"
                      type='text'
                      label="Date of Birth"
                      value={formData?.dob}
                      formate="mm/dd/yyyy"
                      inputProps={{ max: minDateString }} // Set the minimum date

                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="referredBy"
                      label="Referred By"
                      value={formData?.referredBy}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="referredId"
                      label="Referred Id"
                      value={formData?.referredId}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="walletBalance"
                      label="Wallet Balance"
                      value={formData?.walletBalance}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Account Approval</InputLabel>
                      <>
                        {
                          (formData?.approved == true || formData?.approved == false) &&
                          <Select
                            name="approved"
                            value={formData?.approved}
                            onChange={handleInputChange}
                            displayEmpty
                            renderValue={(value) => {
                              if (value == true) {
                                return "Approved";
                              } else if (value == false) {
                                return "Not Approved";
                              }
                            }}
                          >
                            <MenuItem
                              selected={formData?.approved == true}
                              value={true}>Approved</MenuItem>
                            <MenuItem
                              selected={formData?.approved == false}
                              value={false}>Not Approved</MenuItem>
                          </Select>


                        }
                        {
                          !formData?.hasOwnProperty("approved") &&
                          <Select
                            name="approved"
                            value={formData?.approved}
                            onChange={handleInputChange}
                            displayEmpty
                            renderValue={(value) => {
                              if (value == true) {
                                return "Approved";
                              } else if (value == false) {
                                return "Not Approved";
                              }
                            }}
                          >
                            <MenuItem
                              value={true}>Approved</MenuItem>
                            <MenuItem
                              value={false}>Not Approved</MenuItem>
                          </Select>
                        }
                      </>
                    </FormControl>

                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth variant='standard'>
                      <InputLabel>Car Approval</InputLabel>
                      <>
                        {(formData?.carApproved == true || formData?.carApproved == false) && <Select
                          name="carApproved"
                          value={formData?.carApproved}
                          onChange={handleInputChange}
                          displayEmpty
                          renderValue={(value) => {
                            if (value == true) {
                              return "Approved";
                            } else if (value == false) {
                              return "Not Approved";
                            }
                          }}
                        >
                          <MenuItem
                            selected={formData?.carApproved == true}
                            value={true}>Approved</MenuItem>
                          <MenuItem
                            selected={formData?.carApproved == false}
                            value={false}>Not Approved</MenuItem>
                        </Select>
                        }

                        {
                          !formData?.hasOwnProperty("carApproved")
                          &&
                          <Select
                            name="carApproved"
                            value={formData?.carApproved}
                            onChange={handleInputChange}
                            displayEmpty
                            renderValue={(value) => {
                              if (value == true) {
                                return "Approved";
                              } else if (value == false) {
                                return "Not Approved";
                              }
                            }}
                          >
                            <MenuItem

                              value={true}>Approved</MenuItem>
                            <MenuItem
                              value={false}>Not Approved</MenuItem>
                          </Select>

                        }
                      </>
                    </FormControl>
                  </Grid>
                </Grid> */}
            </Grid>


            <Grid

              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
              style={{ marginTop: "10px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  marginRight: "10px"
                }}
                onClick={onClose}>Close</Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSave}>Save</Button>
            </Grid>
          </Box>
        </Modal>
      </div>
  );
}
