import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>About Us!</h4>
            <h2>
              Feel your journey <br /> with <span>GoBabyGo!</span>
            </h2>
            <p>
              Your Destination, Our Commitment! {' '}
            </p>
          </div>
        </div>
      </section>

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
          <h2>About GoBabyGo</h2>
          <h3>Empowering Drivers, Zero Commission</h3>
          <p style={{ color: '#666666', padding: 0 }}>
            At GoBabyGo Cabs, we believe that the foundation of a successful
            ride-hailing platform lies in empowering its drivers. Our mission is
            to revolutionize the way drivers and riders experience ride-hailing
            services. Established with a vision to create a fair and supportive
            ecosystem, GoBabyGo Cabs is committed to providing drivers with the
            tools and opportunities they need to thrive in the economy, all
            while ensuring that they receive 100% of their earnings.
          </p>
          <h3>Our Mission</h3>
          <p style={{ color: '#666666', padding: 0 }}>
            Our mission is simple yet transformative: We aim to redefine the
            ride-hailing industry by prioritizing the welfare of our drivers. We
            understand that drivers are the backbone of our platform, and their
            satisfaction directly translates to better experiences for our
            riders. Our unwavering commitment to empowering drivers sets us
            apart and is at the core of everything we do.
          </p>
          <h3>Zero Commission Model</h3>
          <p style={{ color: '#666666', padding: 0 }}>
            Unlike many other ride-hailing platforms that charge exorbitant
            commission fees from drivers, GoBabyGo Cabs has taken a bold step by
            introducing a 0% commission model. This means that every driver on
            our platform retains every dollar they earn from their trips. We
            believe that drivers deserve to reap the full benefits of their hard
            work, and by eliminating commission fees, we ensure that their
            efforts are rewarded justly.
          </p>
          <h3>Driver-Centric Approach</h3>
          <p style={{ color: '#666666', padding: 0 }}>
            Our driver-centric approach is at the heart of our operations. We
            believe that drivers are more than just service providers; they are
            partners who contribute to the success of our platform. Through a
            combination of advanced technology, transparent policies, and
            dedicated support, we enable drivers to take control of their
            earnings and work on their own terms. This not only enhances their
            overall job satisfaction but also creates a positive impact on the
            quality of service provided to our riders.
          </p>
          <h3>Innovation and Technology</h3>
          <p style={{ color: '#666666', padding: 0 }}>
            Innovation and technology are driving forces behind GoBabyGo Cabs.
            Our user-friendly app provides drivers with real-time data,
            efficient navigation tools, and insights to maximize their earnings.
            We continuously invest in cutting-edge solutions to enhance the
            driving experience, making it smoother, safer, and more rewarding.
          </p>
          <h3>Community Building</h3>
          <p style={{ color: '#666666', padding: 0 }}>
            GoBabyGo Cabs is more than just a transportation service; it's a
            community. We foster a sense of belonging and camaraderie among our
            drivers. Through events, meetups, and digital forums, we provide a
            platform for drivers to connect, share experiences, and support one
            another. This sense of community strengthens their bond with the
            platform and enhances their overall experience.
          </p>
          <h3>Safety First</h3>
          <p style={{ color: '#666666', padding: 0 }}>
            The safety of both drivers and riders is of paramount importance to
            us. We implement rigorous safety measures, including thorough driver
            background checks, vehicle inspections, and real-time monitoring. By
            ensuring a secure environment, we create a trust-based ecosystem
            that benefits everyone involved.
          </p>
          <h3>Join Us in Empowering Drivers</h3>
          <p style={{ color: '#666666' }}>
            At GoBabyGo Cabs, we invite both drivers and riders to be part of a
            movement that challenges the norms of the ride-hailing industry. By
            embracing a zero-commission model and prioritizing driver
            empowerment, we're reshaping the future of transportation services.
            Join us in this journey of fairness, transparency, and empowerment.
            Together, we can drive positive change and create a better future
            for all.
          </p>
        </div>

        {/* <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              position: 'relative',
              marginTop: '30px',
              minHeight: '32px',
              color: '#383838',
              textDecoration: 'none',
            }}
          >
            {t('about_us')}
          </h2>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Welcome to Gobabygo, the future of transportation! We are a
            revolutionary transportation startup that seeks to revolutionise how
            people travel by offering a cutting-edge app-based platform for
            customers to hail rides and drivers to conveniently charge fares and
            accept payments. Our fundamental objective is to build a sustainable
            ecology that is advantageous to both drivers and passengers and that
            is accessible and affordable to everyone.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            At gobabygo, we think that drivers should be empowered and given the
            chance to make some decent money. We have developed a mechanism that
            drastically lowers the commission costs paid by drivers, allowing
            them to keep a bigger share of their earnings in contrast to
            conventional ride-hailing platforms that charge high commissions. We
            hope to help them support their way of life and enhance their
            general quality of life by doing this.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            One of our key strategies for achieving sustainability and
            affordability is the implementation of a subscription-based model.
            This innovative approach enables passengers to enjoy the benefits of
            our services at a reduced cost. We aim to make sure that everyone,
            regardless of their financial situation, has access to safe and
            cheap transportation.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Our dedication to serving tier 2 and tier 3 cities and linking the
            entire country sets us apart from our rivals. We truly think that
            access to transport services should not just be restricted to
            metropolitan areas, but also extend to residents of smaller cities
            and villages. By extending our service to these areas, we hope to
            close the transportation gap and offer practical mobility solutions
            to previously underserved groups.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            We also take great pride in our commitment to environmental
            responsibility and sustainability. To help the environment become
            cleaner and greener and reduce carbon emissions, we actively urge
            drivers to choose eco-friendly vehicles. Our goal is to
            simultaneously improve the current transport system and make it more
            sustainable for coming generations.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Gobabygo is not just a transportation company; it is a movement
            towards a better future. We envision a world where transportation is
            efficient, affordable, sustainable, and accessible to all. Join us
            on this journey as we revolutionize the transportation industry, one
            ride at a time. Together, we can create a connected nation where no
            city is left behind.
          </p>
          <br />
        </div> */}
      </div>

      <Footer />
    </div>
  );
}
