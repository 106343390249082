import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Disclaimer(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />

      

      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
         <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>DISCLAIMER</h2>
                  
 <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>GOBABYGO makes no assertion that the application or any
aspect thereof will be devoid of errors or interruptions, nor does it guarantee the correction of any defects or the attainment of specific outcomes through your use of the application. The provision of services is executed on an "as is" and "as available" basis. GOBABYGO disclaims all forms of declarations and warranties, whether expressed, implied,
or stipulated by law, that are not explicitly delineated within these stipulated terms. Such disclaimed warranties encompass but are not limited to, implied warranties of merchantability, suitability for particular purposes, and non-infringement.<br/> <br/>

In addition, GOBABYGO makes no representation, warranty, or assurance concerning the reliability, punctuality, quality, appropriateness, or availability of the services, or any services or goods procured via the utilization of the services. Nor does it assure uninterrupted or error-free provision of the services. The quality, appropriateness, safety, or capabilities of third-party providers are not guaranteed by GOBABYGO. <br/> <br/>

By utilizing the services, you acknowledge and agree that any risks arising from your engagement with the services and any services or goods acquired in conjunction with them are solely your responsibility. This allocation of risk endures exclusively with you, up to the maximum scope permitted by the prevailing applicable law.<br/> <br/>

(i) Your utilization of or reliance upon the services, or your incapacity to access or utilize said services; or <br/> 
(ii) Any transaction or association between you and any third-party service provider. This encompasses scenarios where GOBABYGO has been apprised of the potential for such damages. <br/> <br/>


In situations where delays or performance failures transpire due to causes that are beyond GOBABYGO's reasonable control, GOBABYGO shall not be held liable.
You acknowledge that third-party transportation providers, who fulfil transportation services requested through certain brands of requests, might extend ride-sharing or peer-topeer transportation services. These providers may not possess professional licenses or authorizations.<br/> <br/>

Under no circumstances shall GOBABYGO's cumulative liability to you in relation to the services, for all forms of damages, losses, and legal bases for claims, surpass an aggregate total of Fifty thousand Indian Rupees (INR 50,000).<br/> <br/>

It should be noted that while GOBABYGO's services allow you to request and schedule transportation, goods, or logistics services from third-party providers, you are in agreement that GOBABYGO bears no responsibility or liability towards you regarding any transportation, goods, or logistics services provided by third-party providers. This is except as explicitly stipulated within the terms presented herein. <br/> <br/>

The limitations and disclaimers stated in this section are not intended to curtail liability or modify your rights as a consumer. Such rights cannot be excluded in compliance with relevant applicable law.<br/> <br/>

In no event shall GOBABYGO, its parents, affiliates, subsidiaries, service providers, licensors, or the officers, directors, employees, shareholders, interns, suppliers, or agents of each of them, be liable for any injury, loss, claim, damages of any kind, including without limitation any direct, special, incidental, indirect, exemplary, punitive or consequential damages, including lost profits, lost revenue, savings, loss of data, replacement costs, or any similar damages, whether or not advised of the possibility of such damages and on the theory of liability whatsoever, arising out of or in connection with the use or performance of the services.<br/> <br/>

Furthermore, the foregoing limitations shall apply even if GOBABYGO has been advised of the potential for such damages. You agree that this provision encompasses all potential claims, causes of action, liabilities, and disputes, whether in contract, tort, statutory, or any other legal theory. <br/> <br/>

 <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>INDEMNITY:</strong>
                  </p>


You agree to indemnify and hold GOBABYGO and its officers, directors, employees and agents harmless from any and all claims, demands, losses, liabilities, and expenses
(including attorneys’ fees) arising out of or in connection with:

  <ul class="ul-li-tnc">
			<li >your use of the Services or services or goods obtained through your use of the Services;</li>
			<li >your breach or violation of any of these Terms;</li>
			<li >GOBABYGO’s use of your User Content; or </li>
			<li > your violation of the rights of any third party, including Third Party Providers.</li>
    </ul>
</p>

   
          <br />
         </div>
      </div>

      <Footer />
    </div>
  );
}
