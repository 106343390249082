import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Safety(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>Safety!</h4>
            <h2>
              Feel your journey <br /> with <span>GoBabyGo!</span>
            </h2>
            <p>
              Every ride is a secure journey toward your destination 
 <br />
              because your well-being matters most!{' '}
            </p>
          </div>
        </div>
      </section>

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
          <h2>Safety with GoBabyGo</h2>
        
          <p style={{ color: '#666666', padding: 0 }}>
            At GoBabyGo Cabs, your safety is our utmost priority. We are committed to ensuring that your journey 
with us is not only convenient and comfortable but also safe. Here are some essential safety tips and 
guidelines to keep in mind while using our services
          </p>

  <p style={{ color: '#666666', padding: 0 }}>

<b>1. Buckle Up:</b> Always remember to fasten your seatbelt/helmet before the journey begins. 
Seatbelts/Helmets are your first line of defense in case of any unexpected situations.

<br/><br/><b>2. Verify Your Driver:</b> Before getting into the cab, make sure to verify the driver's identity. You will 
receive information about your driver, including their name, photo, address and every mandatory details 
through the app and website (in detail). Confirm these details before entering the vehicle.

<br/><br/><b>3. Share Your Trip Details:</b> Use our "Share Trip" feature with a friend or family member. This will allow 
them to track your journey in real-time and receive updates on your progress.

<br/><br/><b>4. Stay Alert:</b> While in the cab, remain attentive to your surroundings. If you feel uncomfortable at any 
point during the ride, don't hesitate to speak up or ask the driver to pull over at a safe location.

<br/><br/><b>5. Keep Valuables Secure:</b> Keep your belongings secure and close to you. Avoid displaying valuable items 
like laptops, phones, or jewelry that could attract unwanted attention.

<br/><br/><b>6. Follow COVID-19 Guidelines:</b> In these challenging times, we are dedicated to following all 
recommended health guidelines. Please wear a mask during the ride, use hand sanitizer frequently, and 
maintain social distancing whenever possible


<br/><br/><b>7. Emergency Contact:</b>  Save the emergency contact number provided by GoBabyGo Cabs in your phone. 
If you encounter any issues during your journey, you can quickly reach out for assistance.
<br/><br/><b>8. Stay in the Cab Until You Reach Your Destination:</b>  For your safety, it's advisable to remain inside the 
cab until you reach your destination. If you need to stop somewhere along the way, choose well-lit and 
populated areas.
<br/><br/><b>9. Rate and Provide Feedback:</b>  After your journey, take a moment to rate your experience and provide 
feedback through the app. Your input helps us improve our services and maintain the highest safety 
standards.
<br/><br/>From meticulously selecting and training drivers to maintaining vehicles and implementing state-of-the art safety technologies, we are dedicated to creating an environment where passengers can travel with 
peace of mind. Our commitment to safety remains unwavering, and we will continue to innovate and 
improve to ensure that every ride with GoBabyGo Cabs is a safe and pleasant journey. Your well-being is 
our priority. Travel with confidence and enjoy the ride.
   </p>

 </div>

        {/* <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              position: 'relative',
              marginTop: '30px',
              minHeight: '32px',
              color: '#383838',
              textDecoration: 'none',
            }}
          >
            {t('about_us')}
          </h2>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Welcome to Gobabygo, the future of transportation! We are a
            revolutionary transportation startup that seeks to revolutionise how
            people travel by offering a cutting-edge app-based platform for
            customers to hail rides and drivers to conveniently charge fares and
            accept payments. Our fundamental objective is to build a sustainable
            ecology that is advantageous to both drivers and passengers and that
            is accessible and affordable to everyone.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            At gobabygo, we think that drivers should be empowered and given the
            chance to make some decent money. We have developed a mechanism that
            drastically lowers the commission costs paid by drivers, allowing
            them to keep a bigger share of their earnings in contrast to
            conventional ride-hailing platforms that charge high commissions. We
            hope to help them support their way of life and enhance their
            general quality of life by doing this.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            One of our key strategies for achieving sustainability and
            affordability is the implementation of a subscription-based model.
            This innovative approach enables passengers to enjoy the benefits of
            our services at a reduced cost. We aim to make sure that everyone,
            regardless of their financial situation, has access to safe and
            cheap transportation.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Our dedication to serving tier 2 and tier 3 cities and linking the
            entire country sets us apart from our rivals. We truly think that
            access to transport services should not just be restricted to
            metropolitan areas, but also extend to residents of smaller cities
            and villages. By extending our service to these areas, we hope to
            close the transportation gap and offer practical mobility solutions
            to previously underserved groups.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            We also take great pride in our commitment to environmental
            responsibility and sustainability. To help the environment become
            cleaner and greener and reduce carbon emissions, we actively urge
            drivers to choose eco-friendly vehicles. Our goal is to
            simultaneously improve the current transport system and make it more
            sustainable for coming generations.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Gobabygo is not just a transportation company; it is a movement
            towards a better future. We envision a world where transportation is
            efficient, affordable, sustainable, and accessible to all. Join us
            on this journey as we revolutionize the transportation industry, one
            ride at a time. Together, we can create a connected nation where no
            city is left behind.
          </p>
          <br />
        </div> */}
      </div>

      <Footer />
    </div>
  );
}
