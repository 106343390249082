// import React, { useState, useEffect} from 'react';
// import { Grid, Typography, Button, Box } from '@mui/material';
// import backgroundImage from '../assets/img/background.jpg';
// import useStyles from '../styles/styles';
// import { useTranslation } from "react-i18next";
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from "react-redux";
// import {calcEst, showEst, optionsRequired} from '../common/sharedFunctions';

// const Hero = (props) => {
//   const classes = useStyles();
//   const { t, i18n  } = useTranslation();
//   const isRTL = i18n.dir();
//   const navigate = useNavigate();
//   const role = props.role;
//   const [loggedIn, setLoggedIn] = useState(false);
//   const auth = useSelector(state => state.auth);

//   useEffect(()=>{
//     if(auth.profile && auth.profile.uid){
//       setLoggedIn(true);
//     }else{
//       setLoggedIn(false);
//     }
//   },[auth.profile]);

//   return (
//     <Box className={classes.heroBox} sx={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} style={{direction:isRTL === 'rtl'? 'rtl':'ltr'}}>
//       <Grid container spacing={12} className={classes.gridContainer}>
//         <Grid item xs={12} md={7}>
//           <Typography variant="h3" fontWeight={500} className={classes.titleMain}>
//           {t('book_your_title')}
//           </Typography>
//           <Typography variant="h6" className={classes.subtitleMain}>
//            {t('about_us_content2')}
//           </Typography>
//             <Button
//               variant="contained"
//               color="primary"
//               sx={{ width: '200px', fontSize: '16px' }}
//               onClick={(e) => { e.preventDefault();
//                 if(loggedIn){
//                  navigate( ((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings')
//                 }else{
//                   navigate('/login')
//                 }
//               }}
//             >
//             {role === 'driver' || (calcEst && !showEst && !optionsRequired &&  (role === 'admin' || role === 'fleetadmin'))? t('info') : t('book_your_ride_menu')}
//             </Button>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default Hero;





// Second Main Hero
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import bannerImage from "../assets/img/banner-image.jpeg"
import { calcEst, showEst, optionsRequired } from '../common/sharedFunctions';

export default function Hero(props) {
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);
  return (
    <>
      <div id="sidebox-overlay"></div>
      <div className="slider-section">
        <div className="main-slider-2">
          <img src={bannerImage} alt="bannerImage" style={{ width: "100%" }} />
          {/* <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="slider-img"><img src="assets/img/slider-bg.jpg" alt="img"
                className="kenburns" style={{ animationDuration: '12000ms' }} /></div>
              <div className="slider-content-wrap d-flex align-items-center text-left">
                <div className="container">
                  <div className="slider-content text-center pb-60">
                    <div className="slider-caption medium">
                      <div className="inner-layer">
                        <div data-animation="fade-in-bottom"
                          data-delay="0.3s">Travel
                          securely with us!</div>
                      </div>
                    </div>
                    <div className="slider-caption big">
                      <div className="inner-layer">
                        <div data-animation="fade-in-bottom"
                          data-delay="0.5s">Book your taxi
                          <br />service today!</div>
                      </div>
                    </div>
                    <div className="slider-caption small">
                      <div className="inner-layer">
                        <div data-animation="fade-in-bottom" style={{ width: '70%', margin: 'auto' }}
                          data-delay="0.7s"
                          data-duration="1s">Gobabygo has created a system that significantly reduces commission costs for drivers, enabling them to retain more earnings compared to traditional ride-hailing platforms.
                          !</div>
                      </div>
                    </div>
                    <div className="slider-btn" onClick={(e) => {
                      e.preventDefault();
                      if (loggedIn) {
                        navigate(((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings')
                      } else {
                        navigate('/login')
                      }
                    }}>
                      <a href="book-taxi.html" className="default-btn"
                        data-animation="fade-in-bottom"
                        data-delay="0.9s">Book Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="swiper-nav swiper-next"><i className="las la-long-arrow-alt-right"></i></div>
          <div className="swiper-nav swiper-prev"><i className="las la-long-arrow-alt-left"></i></div> */}
        </div>
      </div>
    </>
  )
}
