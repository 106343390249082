import {
    FETCH_COLLABORATIONSLIST,
    FETCH_COLLABORATIONSLIST_SUCCESS,
    FETCH_COLLABORATIONSLIST_FAILED,
    EDIT_COLLABORATIONSLIST  } from "../store/types";
  
  export const INITIAL_STATE = {
    collaborations:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }

  
  export const collaborationslistReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_COLLABORATIONSLIST:
        return {
          ...state,
          loading:true
        };
      case FETCH_COLLABORATIONSLIST_SUCCESS:
        return {
          ...state,
          collaborations:action.payload,
          loading:false
        };
      case FETCH_COLLABORATIONSLIST_FAILED:
        return {
          ...state,
          collaborations:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_COLLABORATIONSLIST:
        return state;
      default:
        return state;
    }
  };