import {
    FETCH_COLLABORATIONSLIST,
    FETCH_COLLABORATIONSLIST_SUCCESS,
    FETCH_COLLABORATIONSLIST_FAILED,
    EDIT_COLLABORATIONSLIST  } from "../store/types";
  import { firebase } from '../config/configureFirebase';
  import { onValue, push, set, remove } from "firebase/database";
  
  export const fetchCollaborations = () => (dispatch) => {
  
    const {
        collaborationslistRef
    } = firebase;
  
    dispatch({
      type: FETCH_COLLABORATIONSLIST,
      payload: null
    });
    onValue(collaborationslistRef, snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_COLLABORATIONSLIST_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_COLLABORATIONSLIST_FAILED,
          payload: "No promos available."
        });
      }
    });
  };
  
  export const editCollaborationslist = (collaboration, method) => (dispatch) => {
    const {
        collaborationslistRef, 
        collaborationslistEditRef
    } = firebase;
    dispatch({
      type: EDIT_COLLABORATIONSLIST,
      payload: { method, collaboration }
    });
    if (method === 'Add') {
      push(collaborationslistRef, collaboration);
    } else if (method === 'Delete') {
      remove(collaborationslistEditRef(collaboration.id));
    } else {
      set(collaborationslistEditRef(collaboration.id),collaboration);
    }
  }