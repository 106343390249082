import React, { useState } from 'react';
import { Button } from '@mui/material';

import doorstepPickup from "../assets/img/doorstep-pickup.jpeg"
import affordableRides from "../assets/img/affordable-rides.jpeg"
import quickSafeRide from "../assets/img/quick-safe-ride.jpeg"
import insurancePolicy from "../assets/img/insurance-policy.jpeg"
import noCommision from "../assets/img/no-commission.jpeg"
import decentEarning from "../assets/img/decent-earning.jpeg"

import Slider from 'react-slick';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const customers = [
    { url: doorstepPickup, text: "High Quality", description: "Say goodbye to the hassle of waiting or commuting to a designated pick-up point. Our drivers will pick you up right from your doorstep, ensuring a seamless start to your journey." },
    { url: affordableRides, text: "Affordable Prices", description: "Enjoy economical fares with GoBabyGoCabs. Affordable options without compromising comfort or quality. Your reliable choice for wallet-friendly transportation." },
    { url: quickSafeRide, text: "Satisfaction Guaranteed", description: "Riders who use GoBabyGo Cabs get the best of both worlds: a speedy, effective trip without sacrificing safety. A safe travel experience is guaranteed by our experienced drivers, meticulously maintained vehicles, and real-time tracking." },
];

const drivers = [
    { url: decentEarning, text: "Zero Commission", description: "The Zero Commission Model used by GoBabyGo Cabs is a ground-breaking strategy in the ride-hailing sector. And we are the first company to introduce this 0-commission model." },
    { url: noCommision, text: "Decent Earning", description: "Make significant money with GoBabyGo Cabs! We put our drivers' financial well-being first as the industry's first 0% commission provider. Enjoy the full fare you are entitled to, with no commission." },
    { url: insurancePolicy, text: "Insurance Coverage", description: "Gobabygocabs provides drivers with full insurance coverage to give them peace of mind while driving. With Gobabygocabs, you can travel with assurance knowing that every mile is covered." },
];

function WhyChooseUs() {

    const [currentSection, setCurrentSection] = useState('customers'); // default is 'customers'
    const section = currentSection === 'customers' ? customers : drivers;


    const activeButtonStyle = {
        backgroundColor: '#f9c935',
        color: '#000000',
        border: '2px solid #f9c935',
        width: '200px'
    };

    const inactiveButtonStyle = {
        backgroundColor: 'transparent',
        color: '#000000',
        border: '2px solid rgba(0, 0, 0, 0.1)',
        width: '200px'
    };



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Box sx={{ width: '80%', padding: '2rem 0', margin: '4% auto' }}>
                <h2 style={{ fontSize: '42px', textAlign: 'center', paddingBottom: "2%" }}>Why Choose Us?</h2>
                <style jsx="true">{`
        .slick-prev:before, .slick-next:before {
            color: black;
        }

        .slick-dots li button:before {
            color: gray;
        }

        .slick-dots li.slick-active button:before {
            color: black;
        }
      `}</style>

                <Box display="flex" justifyContent="center" marginBottom={2}>
                    <Button style={currentSection === 'customers' ? activeButtonStyle : inactiveButtonStyle} onClick={() => setCurrentSection('customers')}>
                        Customers
                    </Button>
                    <Button style={currentSection === 'drivers' ? activeButtonStyle : inactiveButtonStyle} onClick={() => setCurrentSection('drivers')}>
                        Drivers
                    </Button>
                </Box>

                <Slider {...settings}>
                    {section.map((item, index) => (
                        <div key={index} style={{ textAlign: 'center', width: '70%' }}>
                            <img src={item.url} alt={`choice-${index}`} width="60%" style={{ margin: 'auto', borderRadius: '100%', objectFit: 'cover' }} />
                            <Typography variant="h6" style={{ marginTop: '20px', textAlign: 'center', fontSize: '24px' }}>
                                {item.text}
                            </Typography>
                            <Typography variant="body1" color="initial" style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>{item.description}</Typography>
                        </div>
                    ))}
                </Slider>
            </Box >
        </div >
    );
}

export default WhyChooseUs;
