import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function PrivacyPolicy(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />

      

      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
         <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>PRIVACY POLICY</h2>
                  
         <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
         At GOBABYGO, we know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy.<br /><br />
By using or accessing the Service in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.<br/><br/>
Remember that your use of GOBABYGO’s Service is at all times subject to our Terms of Service, which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in the Terms of Service. Our Privacy Policy applies to all users of the GOBABYGO App and its website: <a href="https://gobabygocabs.com/" target="_blank">gobabygocabs.com</a><br/><br/>
Notwithstanding the foregoing or anything to the contrary in this Privacy Policy or the Terms of Service, GOBABYGO’s use and transfer to any other app of information received from Google APIs will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy , including the Limited Use requirements.</a><br/><br/>
</p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>What does this Privacy Policy Cover? </strong></p>
  
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Service. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules, or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.
</p>

 
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>Changes to this Privacy Policy </strong></p>
  
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
GOBABYGO may change this Privacy Policy from time to time. Laws, regulations, and industry standards evolve, which may make those changes necessary, or we may make changes to our Service or business. We will post the changes to this page and encourage you to review our Privacy Policy to stay informed. If we make changes that materially alter your privacy rights, we will do our best to alert you to changes by placing a notice on the GOBABYGO App and also on the website, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Service, and you are still responsible for reading and understanding them. If you use the Service after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.
</p>
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>What Personal Data does GOBABYGO Collect? </strong></p>
  
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
We collect Personal Data about you from you: <br/>

(i) when you provide such information directly to us, and <br/>
(ii) when Personal Data about you is automatically collected in connection with your use of our Service.<br/>
(iii) Third parties, when they provide us with Personal Data about you and when we seek out information for Profiles, as discussed below (“Third Parties”). Third Parties that share your Personal Data with us include:

<ul class="ul-li-tnc">
	<li >Service providers. For example, we may use analytics service providers to analyze how you interact and engage with the Service or third parties may help us provide you with customer support. </li>
<li >Other networks connected to the Service. If you provide any third-party account credentials to us or otherwise sign in to the Service through a third-party site or service (for example, using LinkedIn or any CRM), you understand some content and/or information in those accounts may be transmitted into your Account with us.</li>
<li >Advertising partners. We receive information about you from some of our service providers who assist us with marketing or promotional services related to how you interact with our websites, applications, products, services, advertisements, or communications.</li>
</ul>
</p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
GOBABYGO also creates profiles of people and companies, which we call “Profiles,” from different sources. Once we have collected information (primarily business-related information) about a person or company, we combine multiple mentions of the same person or company into a Profile. Profiles are then made available to users of the Service, and our customers and strategic partners.
<br/>
When we create or enhance Profiles about individuals, we may collect this information from multiple sources, including:

<ul class="ul-li-tnc">
 
<li >Publicly-available web sources that we scan using technology or manual methods.</li>
<li >User contributions about themselves or other people and companies.</li>
<li >Research conducted internally by GOBABYGO</li>
<li >Other companies and data partners that license information to us.</li>
</ul>
</p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>What Categories of Personal Data We Collect </strong></p>
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>The following chart details the categories of Personal Data that we collect and have collected over the past twelve (12) months. Throughout this Privacy Policy, we will refer back to the categories of Personal Data listed in this chart (for example, “Category A. Personal identifiers”).
</p>
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>Information Collected Automatically: Like many other commercial websites, we may also gather information in connection with your use of the Service through the use of cookies and other similar technologies such as server logs, pixel tags, web beacons, user website activity and JavaScript (collectively, “Cookies”) to enable us to recognize your web browser or device and tell us how and when you visit and use our Service, to analyze trends, to learn about our user base, and to operate and improve our Service. Cookies are small pieces of data– usually text files–placed on your computer, tablet, phone, or similar device when you use that device to visit our Service. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Service does not support “Do Not Track” requests sent from a browser at this time.
<br/>
We use the following types of Cookies:

<ul class="ul-li-tnc">
 
<li >Essential Cookies. Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Service. Disabling these Cookies may make certain features and services unavailable.
</li>
<li >Functional Cookies. Functional Cookies are used to record your choices and settings regarding our Service, maintain your preferences over time and recognize you when you return to our Service. These cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).
</li>
<li >Performance/Analytical Cookies. Performance/Analytical Cookies allow us to understand how visitors use our Service such as by collecting information about the number of visitors to the Service, what pages visitors view on our Service and how long visitors are viewing pages on the Service. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Service’s content for those who engage with our advertising. For example, Google, Inc. (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your visits to the Service is subject to the Google Analytics Terms of Service and the Google Privacy Policy. You have the option to opt-out of Google’s use of cookies by visiting the Google advertising opt-out page at https://policies.google.com/technologies/ads or the Google Analytics Opt-out Browser Add-on at https://tools.google.com/dlpage/gaoptout/.
</li>
<li >Retargeting/Advertising Cookies. Retargeting/Advertising Cookies collect data about your online activity and identify your interests so that we can provide advertising that we believe is relevant to you. For more information about this, please see the section below titled “Information about Interest-Based Advertisements.”
</li>
</ul>
<br/>

As a user of our mobile application, you have the ability to manage Cookies in accordance with your preferences. Most mobile operating systems and app platforms offer settings to control the use of Cookies.<br/><br/>

1. Disabling Cookies: You can choose to disable Cookies entirely through your device's settings. This action will prevent the application from storing or accessing new Cookies. <br/>
2. Cookie Notifications: Depending on the features of your mobile device and our application, you may be prompted to consent to the use of Cookies on a case-by-case basis. <br/>
3. Deleting Stored Cookies: You also have the option to delete any Cookies that have been previously stored on your device through the app's settings.<br/><br/>

Please be aware that if you decide to disable or delete Cookies, it may be necessary to reconfigure certain preferences within the app each time you use it. Additionally, certain services and functionalities within the app may be limited or unavailable without the use of Cookies.<br/><br/>
</p>
 
 
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
How We Use Your Personal Data<br/><br/>
We process Personal Data to operate, improve, understand and personalize our Service. We use Personal Data for the following purposes:

<ul class="ul-li-tnc">
 
<li>To meet or fulfil the reason you provided the information to us.</li>
<li>To communicate with you about the Service, including Service announcements, updates or offers.</li>
<li>To provide support and assistance for the Service.</li>
<li> To create and manage your Account or other user profiles.</li>
<li>To personalize your experience, website content and communications based on your preferences, including targeted offers and ads served through the Service.</li>
<li>To process orders or other transactions.</li>
<li>To respond to user inquiries and fulfil user requests.</li>
<li> To market, improve, and develop the Service, including testing, research, analysis, and product development (including creation,</li>
<li> To protect against or deter fraudulent, illegal or harmful actions and maintain the safety, security and integrity of our Service.</li>
<li>To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Service.</li>
<li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
<li> For any other business purpose stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).</li>

</ul>

</p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated, or incompatible purposes without providing you notice.<br/><br/>
As noted in the list above, we may communicate with you if you’ve provided us with the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers on behalf of other businesses or email you about your use of the Service. Also, we may receive a confirmation
when you open an email from us, which helps us improve our Service. If you do not want to receive communications from us, please indicate your preference on the Account Information page or contact us page of the App.</p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>How We Share Your Personal Data</strong><br/><br/>
<strong>Disclosures of Personal Data for a Business Purpose</strong>
We disclose your Personal Data to service providers and other parties for the following business purposes:
<ul class="ul-li-tnc">


<li>Performing services on our behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.</li>
<li>Auditing related to a current interaction and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance with this specification and other standards.</li>
<li>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</li>
<li>Debugging to identify and repair errors that impair existing intended functionality.</li>
<li>Short-term, transient use of Personal Data that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction.</li>
<li>Undertaking internal research for technological development and demonstration.</li>
<li>Undertaking activities to verify or maintain the quality or safety of a service or device that we own, manufacture, was manufactured for us, or we control</li>

  
</ul>
</p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
We disclose your Personal Data to the following categories of service providers and other parties:
<ul class="ul-li-tnc">
<li>Service providers, including:</li>
<li>Subscription management providers and payment processors</li>
<li>Security and fraud prevention consultants</li>
<li>Hosting and other technology and communications providers</li>
<li>Analytics providers</li>
<li>Customer relationship management and customer support providers</li>

<li>Parties who acquire your Personal Data through an acquisition or otherch ange of control.
<ul class="ul-li-tnc">
  <li>
  Personal Data may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part) 
  </li>
</ul>
</li>

<li>Other parties at your direction.
<ul class="ul-li-tnc">
  <li> Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the Service)</li>
  <li> Social media services (if you intentionally interact with them through your use of the Service)</li>
<li> Third-party business partners whom you access through the Service</li>
<li> Other parties authorized by you</li>
</ul>
</li>

<li>Business partners
<ul class="ul-li-tnc">
  <li>
  In some cases, we may disclose information about your use of the Service to your employer or other users within your organization.
 </li>
</ul>
</li>
  
</ul
>
<li> Over the past twelve months, we have disclosed the following categories of your Personal Data to service providers or other parties for the business purposes listed above:</li>
<li> A. Personal identifiers.</li>
<li>  B. Customer records identified by state law.</li>
<li> C. Protected classification characteristics under state or federal law.</li>
<li>  D. Internet or other similar network activity information.</li>
<li>  E. Geolocation data.</li>
<li>  F. Professional or employment-related information</li>

</p>

 
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>Data Security and Retention for Our App:</strong></p>
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>We are dedicated to securing your Personal Data within our mobile application, employing a comprehensive set of security measures. These measures are chosen based on the nature of the data we handle and the manner in which we process it. Our security practices include, but are not limited to:<br/><br/>

<strong>Secure Data Transmission:</strong> Our mobile app ensures secure data transmission using industry-standard encryption protocols, including SSL (Secure Sockets Layer). <br/> 
<strong>Password Protection:</strong> All account passwords within the app are securely hashed and stored to prevent unauthorized access to user accounts.<br/>
<strong>Preventing CSRF Attacks:</strong> We verify the authenticity of request methods to safeguard against CSRF (cross-site request forgery) attacks, enhancing the security of your app experience.<br/>



<strong>Authentication Measures:</strong> Our employees utilize Single Sign-On (SSO) in combination with robust passwords and enable screen locking on their mobile
devices to ensure the security of their accounts and mobile workstations.<br/>

<strong>Access Control:</strong> Access to our app's back-end infrastructure and payment processors is restricted to individuals with a legitimate need-to-know basis.
Furthermore, access to these systems mandates Two-Factor Authentication (2FA) to provide an additional layer of security.<br/>


<strong>Production Data Access:</strong> Access to production data within the app is meticulously managed and necessitates VPN (Virtual Private Network) access.
Such access is limited to authorized personnel who require it for their specific roles.<br/>


These security measures are meticulously designed to safeguard your Personal Data and maintain the confidentiality, integrity, and availability of the
information entrusted to us via our app. Furthermore, our data retention practices ensure that we retain your data only for as long as necessary to fulfill
the purposes outlined in our Privacy Policy or as mandated by applicable laws and regulations.<br/><br/>


It's important to note that, while we implement robust security measures, no data transmission or storage method can be entirely risk-free. In the event of a
data breach or security incident, we have established protocols to respond promptly and take appropriate action in compliance with relevant laws and
regulations. <br/><br/>


You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the privacy of your
account and other Personal Data that we hold in our records, no security system is impenetrable, and unanticipated system failures or the efforts of malicious actors are an unfortunate reality on the Internet.Therefore,GOBABYGO cannot guarantee that Personal Data during
transmission through the Internet or while stored on our systems or otherwise in our care will be absolutely safe from intrusion by others.<br/><br/>

We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide the Service. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or
required by applicable law, rule or regulation. Afterwards, we retain some information in a depersonalized or aggregated form but not in a way that would identify you personally
<br/><br/>

<strong>Personal Data of Children</strong><br/>
As the Terms of Service noted, we do not knowingly collect or solicit Personal Data from children under 16. If you are a child under 16, please do not attempt
to register for or otherwise use the Service or send us any Personal Data. If we learn we have collected Personal Data from a child under 16, we will delete
that information as quickly as possible. If you believe that a child under 16 may have provided us with Personal Data, please contact us at +91 9873115145..
<br/><br/>

<strong>Instructions for Requesting Data Deletion </strong><br/>
To request data deletion, user may send an email to info@gobabygocabs.com from the email address that is valid or registered with the app data. The subject line of the email should be "GoBabyGo data deletion request" and the email should include all necessary details or user can call customer support team on +91 9873115145 with registered mobile number to request account and data deletion request.
<br/><br/>

<strong>Contact Information:</strong><br/>
If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data, or your choices and rights
regarding such use, please do not hesitate to contact  contact your +91 9873115145 representative or email us at info@gobabygocabs.com.<br/><br/>
 
Your visit and any dispute over privacy are subject to this Policy. The said Policy shall be governed by and construed in accordance with the laws of the Republic of India. Further, it is irrevocably and unconditionally agreed that the courts of Delhi, India shall have exclusive jurisdiction to entertain any proceedings in relation to any disputes arising out of the same.
</p>
          <br />
         </div>
      </div>

      <Footer />
    </div>
  );
}
