import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';
import AboutCEOProfile from "../assets/img/AboutCEOProfile.jpeg"
import './AboutCEO.css'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutCEO(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>About CEO!</h4>
            <h2>
              Feel your journey <br /> with <span>GoBabyGo!</span>
            </h2>
            <p>
              Leading with Vision, Paving the Way{' '}
            </p>
          </div>
        </div>
      </section>


      <div className='about-parent-container'>
        <div className='left-container'>
          <div style={{ margin: 20 }}>
            <div className={classes.container} style={{ padding: '5% 0' }}>
              <h2>About CEO & Founder</h2>
              <p style={{ color: '#666666', padding: 0 }}>
Some stories in the dynamic world of entrepreneurship stand out not only for their creativity but also for the significant social impact they have. Akash Ali's journey from a sales professional (abroad) to the CEO and founder of GoBabyGo Cabs is proof of the value of observation, empathy, and tenacity in developing game-changing ideas. </p>
<p style={{ color: '#666666', padding: 0 }}>
Akash started his work as a sales professional in Dubai after earning an MBA. Within three months, he had secured the post of shop manager because of his remarkable dedication and business acumen, which catapulted him fast up the corporate ladder. His innate leadership abilities and financial savvy were evident from his quick development. He attained yet another milestone in the next three months by rising to the position of mall manager. The considerable impact he would subsequently have been foreshadowed by this meteoric rise, which demonstrated his capacity to not only adapt quickly but also to lead effectively. </p>
<p style={{ color: '#666666', padding: 0 }}>
One weekend, Akash visited his native village in Assam, India, in 2019. He noticed a clear issue that was having an impact on many people's lives here. He observed the transportation problem for people. This encounter represented a turning moment in his professional life and prepared the way for the creation of GoBabyGo. He left his high-earning job and returned back to India. </p>
<p style={{ color: '#666666', padding: 0 }}>
GoBabyGo was conceived out of a sense of compassion and a need that needed to be met.He left his high-earning job and returned back to India permanently and 
recognized a chance to use his knowledge and experience to close the transportation gap that limited the mobility of people with impairments. He imagined a platform or app for a transport service that would not only offer accessible mobility solutions but also encourage inclusion and empowerment because he recognized the transformative potential of technology. </p>
<p style={{ color: '#666666', padding: 0 }}>
GoBabyGo has developed into a cutting-edge platform that redefines mobility for both riders and cab or bike drivers under Akash Ali's direction. His background as a sales executive and rapid rise through the corporate ranks gave him the critical business acumen and managerial skills needed to establish and scale this ground-breaking venture. </p>
<p style={{ color: '#666666', padding: 0 }}>
Akash Ali's transition from the corporate ladder to the head of a ground-breaking mobility business exemplifies his adaptability and dedication to making a difference. His MBA training and practical managerial expertise have established a solid foundation for GoBabyGo's expansion. It shows his entrepreneurial energy and unrelenting commitment to having a long-lasting impact on people's lives that he was able to identify a significant social concern and turn it into a business that provides solutions. </p>
<p style={{ color: '#666666', padding: 0 }}>
Akash Ali continues to be an inspiration as the CEO and founder of GoBabyGo by demonstrating the virtues of inclusivity, inventiveness, and empathy. His tale serves as a lighthouse for ambitious businesspeople, reminding us all that the most worthwhile endeavors frequently result from identifying and resolving the unmet needs of our communities. Under his direction, GoBabyGo not only helps the drivers achieve newfound independence but also helps Akash Ali establish himself as a light of change in the business world.</p>

            </div>
          </div>

        </div>
        <div className='right-container'>
          <img src={AboutCEOProfile} alt="AboutCEOImage" />
        </div>
      </div>

      <Footer />
    </div>
  );
}
