
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function HomeFooter(props) {
  const settings = useSelector(state => state.settingsdata.settings);

  const down = props.download;

  return (
    <footer className="footer-section">


      <div className="footer-mid-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 sm-padding">
              <div className="footer-item">
                <div className="widget-title">
                  <h3>Working Hours</h3>
                </div>
                <ul className="footer-contact">
                  <li>
                    <span></span>We are available 24 X 7 
                  </li>
                   <li>
                  <Link to={"/faq"} style={{ color: '#ccc' }}>FAQ</Link>
                </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 sm-padding">
              <div className="footer-item footer-list">
                <div className="widget-title">
                  <h3>Useful Links</h3>
                </div>
                <ul className="footer-links">
                  <li>
                    <a href={"/privacy-policy"} target='_blank'>Privacy and Policy</a>
                  </li> 			 
		  <li>
                    <a href={"/copyright-policy"} target='_blank'>Copyright Policy</a>
                  </li>
                  <li>
                    <a href={"/tnc"} target='_blank'>Terms of Use</a>
                  </li>
                  <li>
                    <a href={"/refund-policy"} target='_blank'>Refund and Cancellation Policy</a>
                  </li>
 		<li>
                    <a href={"/disclaimer"} target='_blank'>Disclaimer</a>
                  </li>
                  <li>
                    <Link to={"/contact-us"}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 sm-padding">
              <div className="footer-item">
                <div className="widget-title">
                  <h3>Head Office</h3>
                </div>
                <ul className="footer-contact">
                  <li>
                    <span>Location:</span>{settings.CompanyAddress}
                  </li>
                  <li>
                    <span>Join Us:</span>
                    <a href="mailto:{settings.contact_email}" style={{ color: '#ccc' }}>{settings.contact_email}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 sm-padding">
              <div className="footer-item subscribe-wrap">
                <div className="widget-title">
                  <h3>Download App</h3>
                </div>
                <div>
                  {settings && settings.AppleStoreLink ? (
                    <a href={settings.AppleStoreLink}>
                      <img
                        style={{ display: 'block', marginBottom: '8px' }}
                        src={require('../../assets/img/appstore.png').default}
                        alt="Apple Store Link"
                      />
                    </a>
                  ) : null}
                  <span style={{ marginRight: '5px' }}></span>
                  {settings && settings.PlayStoreLink ? (
                    <a href={settings.PlayStoreLink}>
                      <img
                        src={require('../../assets/img/playstore.png').default}
                        alt="Playstore Link"
                      />
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="running-taxi">
          <div className="taxi"></div>
          <div className="taxi-2"></div>
          <div className="taxi-3"></div>
        </div>
      </div>

      <div className="copyright-wrap">
        <div className="container">
          <p>
           © <span id="currentYear"></span>{settings.CompanyName}: All
            Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
