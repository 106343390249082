import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import {colors} from '../components/Theme/WebTheme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function CollaborationList() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editCollaborationslist
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);

  const inputRef = useRef(null);

  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable:"never",
    render: rowData => rowData.createdAt?moment(rowData.createdAt).format('lll'):null,cellStyle:{textAlign:'center', width: 200},headerStyle:{textAlign:'center'} 
  },
    { title: t('Name'), field: 'name',cellStyle:{textAlign:isRTL ==='rtl'? 'right':'left'}},
    { title: t('Address'), field: 'address',cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    // {
    //   title: t('type'),
    //   field: 'promo_discount_type',
    //   lookup: { flat: t('flat'), percentage: t('percentage')},
    //   cellStyle:{textAlign:isRTL ==='rtl'? 'right':'left'}
    // },
    // { title: t('promo_discount_value'), field: 'promo_discount_value', type: 'numeric',cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    { title: t('City'), field: 'city',cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    { title: t('Pincode'), field: 'pincode', type: 'numeric',cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    { title: t('Email'), field: 'email',cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
    { title: t('Mobile'), field: 'contact'},
   
];

// , editComponent: props => (
//   <LocalizationProvider dateAdapter={AdapterDayjs}>
//     <DateTimePicker
//       inputRef={inputRef}
//       renderInput={(props) => <TextField {...props} />}
//       label="DateTimePicker"
//       value={props.rowData.createdAt ? props.rowData.createdAt : new Date()}
//       onChange={(newValue) => {
//         props.onChange(newValue)
//       }}
//       autoFocus={false}
//     />
//   </LocalizationProvider>
// ),

  

  const [data, setData] = useState([]);
  const collaborationdata = useSelector(state => state.collaborationlistdata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (collaborationdata.collaborations) {
      setData(collaborationdata.collaborations);
    } else {
      setData([]);
    }
  }, [collaborationdata.collaborations]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    collaborationdata.loading ? <CircularLoading /> :
    <MaterialTable
      title={'Collaboration List'}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        rowStyle: rowData => ({
        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
      }),
        editable:{
          backgroundColor: colors.Header_Text,
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: 'Lucida Console", "Courier New", monospace'
        },
        headerStyle: {
          position: "sticky",
          top: "0px",
          backgroundColor: colors.Header_Text_back ,
          color: '#fff',
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: 'Lucida Console", "Courier New", monospace'
        }
      }}
      localization={{body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
      editRow: { 
        deleteText: (t('delete_message')),
        cancelTooltip: (t('cancel')),
        saveTooltip: (t('save')) 
        }, 
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      editable={{
        // onRowAdd: newData =>
        //   new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       if(!(newData.name &&  newData.address && newData.city && newData.pincode && newData.email && newData.contact )){
        //         alert(t('no_details_error'));
        //         reject();
        //       }else{
        //         newData['createdAt'] = new Date().getTime();
        //         dispatch(editCollaborationslist(newData,"Add"));
        //         resolve();
        //       }
        //     }, 600);
        //   }),
        onRowUpdate: (newData, oldData) =>
          settings.AllowCriticalEditsAdmin?
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if(!(newData.name &&  newData.address && newData.city && newData.pincode && newData.email && newData.contact )){
                alert(t('no_details_error'));
                reject();
              }else{
                resolve();
                if(newData !== oldData){
                  delete newData.tableData;
                  dispatch(editCollaborationslist(newData,"Update"));
                }
              }
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          }),
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editCollaborationslist(oldData,"Delete"));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
      }}
    />
  );
}
