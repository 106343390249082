import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { api } from 'common';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import AlertDialog from '../components/AlertDialog';

import {
    Typography
} from '@mui/material';
const EditModal = (props) => {
    const {
        setLoading, getUserData,
        open,
        onClose,
        data, setChangesMade,
        onSave, // Add onSave function to handle form submission
    } = props;
    const style = {
        height: "500px",
        overflowY: "scroll",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const adharData = useSelector(state => state.usersdata.adharData);
    
    const rcData = useSelector(state => state.usersdata.rcData);
    const panData = useSelector(state => state.usersdata.panData);
    const licenseData = useSelector(state => state.usersdata.licenseData);
   
    const staticusers = useSelector(state => state.usersdata.staticusers);
    const usersdata = useSelector(state => state.usersdata);

    const [formData, setFormData] = useState();
    const { t, i18n } = useTranslation();
    const cartypes = useSelector(state => state.cartypes);
    const {
        fetchUsersOnce,
        updateLicenseImage,
        panVerification,
        aadhaarXMLVerification,
        aadhaarOTP,
        licenseVerification,
        rcVerification,
        addUser,
        closeAlert,
        
    } = api;
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChangesMade(true)
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const [approved, setApproved] = useState(data?.approved);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFirstImage, setSelectedDrivingLicenseFirstImage] = useState(null);
    const [selectedBackImage, setSelectedDrivingLicenseBackImage] = useState(null);
    const [addressProofFrontImage, setSelectedAddressProofFrontImage] = useState(null);
    const [addressProofBackImage, setSelectedAddressProofBackImage] = useState(null);
    const [rcFrontImage, setSelectedRCFrontImage] = useState(null);
    const [rcBackImage, setSelectedRCBackImage] = useState(null);
    const [selectedProfile, setSelectedProfileImage] = useState(null);
    const auth = useSelector(state => state.auth);
    const settings = useSelector(state => state.settingsdata);
    const [timerInterval, setTime] = useState();
    const [verifyPan, setVerifyPan] = useState(false);

    // Set the time limit to 2 minutes (in seconds)
    const timeLimit = 2 * 60; // 2 minutes * 60 seconds

    // Function to update and display the timer
    function updateTimer() {
        const currentTime = Math.floor((timeLimit - elapsedSeconds) % 60);
        const currentMinutes = Math.floor((timeLimit - elapsedSeconds) / 60);
        // console.log(`Time Remaining: ${currentMinutes}:${currentTime}`);
        elapsedSeconds++;
        setTime(`${currentMinutes}:${currentTime}`)
        if (elapsedSeconds > timeLimit) {
            clearInterval(timerInterval);
            setTime("00:00")
            // console.log("Timer done! 2 minutes have passed.");
            // You can perform any action you want when the timer is done here.
        }
    }


    let elapsedSeconds = 0;

    // Start the timer
    // const timerInterval = setInterval(updateTimer, 1000); // Update every second

    // To stop the timer (if needed), you can use clearInterval like this:
    // clearInterval(timerInterval);

    const dispatch = useDispatch();

    const handleSave = async () => {
        // Implement logic to save the form data and close the modal
        if (!props.isEdit) {
            formData.verifyIdImage = selectedImage;
            formData.licenseImage = selectedFirstImage;
            formData.licenseImageBack = selectedBackImage;
            formData.profile_image = selectedProfile;
            formData.addressProofFrontImage = addressProofFrontImage;
            formData.addressProofBackImage = addressProofBackImage;
            formData.rcBackImage = rcBackImage;
            formData.rcFrontImage = rcFrontImage;
            // Perform data validation
            if (!formData || !formData.firstName) {
                throw new Error(t('proper_input_name'));
            }
            if (settings && settings.license_image_required) {
                if (!formData.licenseImage) {
                    throw new Error(t('proper_input_licenseimage'));
                }

                if (!formData.licenseImageBack) {
                    throw new Error(t('proper_input_licenseimage'));
                }
            }
            if (settings && settings.imageIdApproval) {
                if (!formData.verifyIdImage) {
                    throw new Error(t('upload_id_details'));
                }

                if (!formData.verifyId) {
                    throw new Error(t('upload_id_details'));
                }
            }
            formData.usertype = 'driver';
            formData.createdAt = new Date().getTime();
            const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const reference = [...Array(5)].map(_ => c[~~(Math.random() * c.length)]).join('');
            formData.referralId = reference;

            let role = auth.profile.usertype;
            if (role === 'fleetadmin') {
                formData.fleetadmin = auth.profile.uid;
            }
            // add user dispatch then fetch users make it a promise
            setLoading(true)
            Promise.resolve(dispatch(addUser(formData))).then(async () => {
                let drivers = await dispatch(fetchUsersOnce());
                drivers = drivers.find(driver => driver.email === formData.email);
                await dispatch(updateLicenseImage(drivers.id, formData.licenseImage, 'licenseImage'));
                await dispatch(updateLicenseImage(drivers.id, formData.licenseImageBack, 'licenseImageBack'));
                await dispatch(updateLicenseImage(drivers.id, formData.verifyIdImage, 'verifyIdImage'));
                await dispatch(updateLicenseImage(drivers.id, formData.addressProofFrontImage, 'addressProofFrontImage'));
                await dispatch(updateLicenseImage(drivers.id, formData.addressProofBackImage, 'addressProofBackImage'));
                await dispatch(updateLicenseImage(drivers.id, formData.profile_image, 'profile_image'));
                await dispatch(updateLicenseImage(drivers.id, formData.rcFrontImage, 'rcFrontImage'));
                await dispatch(updateLicenseImage(drivers.id, formData.rcBackImage, 'rcBackImage'));
                onSave(formData)
                setLoading(false)
                onClose();
                setFormData({});

                setSelectedImage(null)
                setSelectedDrivingLicenseFirstImage(null)
                setSelectedDrivingLicenseBackImage(null)
                setSelectedAddressProofFrontImage(null)
                setSelectedAddressProofBackImage(null)
                setSelectedProfileImage(null)

            })
        }
        else {
            if (!formData || !formData.firstName) {
                alert("Please enter first name")
            }

            onSave(formData, data.id)
        }
    };
    const convertToBase64 = (imageUrl) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Enable cross-origin requests
        img.src = imageUrl;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // Get the Base64 data from the canvas
            const base64Image = canvas.toDataURL('image/jpeg'); // You can specify the image format here

            return base64Image;
        };

        img.onerror = (error) => {
            console.error('Error loading image:', error);
        };
    };

    const getBase64 = (file) => {
        // convert file to base64 string
        if (props.isEdit) {

        }
        else {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolve(reader.result)
                };
                reader.onerror = function (error) {
                    reject(error)
                };
            })
        }


    }
    var minDateString = "2006-01-01";
    useEffect(() => {
       
        const today = new Date();
        const minDate = new Date(today && today?.getFullYear() - 15, today?.getMonth(), today?.getDate());
        minDateString = minDate?.toISOString().split('T')[0];
        setApproved(data?.approved)
        if (props.isEdit && data) {
            setFormData(data)
            // get car_type from cartypes\
        } else {
            console.log("else")
            setFormData({
                driverId: "",
                firstName: "",
                lastName: "",
                mobile: "",
                email: "",
                dob: "",
                referredBy: "",
                referredId: "",
                walletBalance: "",
                approved: "",
                carApproved: "",
                vehicleMake: "",
                vehicleModel: "",
                other_info: "",
                vehicleNumber: "",
                rc_engine_co: "",
                rc_chasis_no: "",
                rc_fuel_type: "",
                rc_pollution_expiry: "",
                documentNo: "",
                aadhaar_full_name: "",
                aadhaar_dob: "",
                aadhaar_house: "",
                aadhaar_loc: "",
                aadhaar_street: "",
                aadhaar_state: "",
                aadhaar_zip: "",
                verifyIdImage: "",
                licenseImage: "",
                licenseImageBack: "",
                addressProofFrontImage: "",
                addressProofBackImage: "",
                rcFrontImage: "",
                rcBackImage: "",
                profile_image: "",
                createdAt: "",
                usertype: "",
                fleetadmin: "",
                referralId: "",
                valid_rc: "",
                valid_aadhaar: "",
                valid_license: "",
                valid_id: "",
                valid_id_image: "",
                valid_address_proof: "",
                valid_profile_image: "",
                valid_rcFrontImage: "",
                valid_rcBackImage: "",
                valid_licenseImage: "",
                valid_licenseImageBack: "",
                valid_addressProofFrontImage: "",
                valid_addressProofBackImage: "",
                drivingLicenseNo    : "",
                drivingLicenseExpiry: "",
                drivingLicenseState : "",
                drivingLicenseFrontImage: "",
                drivingLicenseBackImage: "",
                bankName: "",
                bankCode    : "",
                bankAccount : "",    
            })
            // clear all text fields
        }
        // set data.car_type
        setSelectedImage(null)
        setSelectedDrivingLicenseFirstImage(null)
        setSelectedDrivingLicenseBackImage(null)
        setSelectedAddressProofFrontImage(null)
        setSelectedAddressProofBackImage(null)
        setSelectedProfileImage(null)
        // get image file from tag where id vImage 
        // document on load
        if (adharData?.open) {
            setInterval(updateTimer, 1000)
        }
        dispatch(fetchUsersOnce());

    }, [data, adharData,props.isEdit])


    useEffect(() => {
        if (verifyPan) {
            dispatch(fetchUsersOnce());
            let user = staticusers?.find((user) => {
                return user.id == data?.id
            })
            setFormData(user)
        }
        console.log(formData,"props.formData")
    }, [staticusers, verifyPan]);
    const updatePan = async () => {
        setTimeout(() => {
            setVerifyPan(false)
        }, 10000)
    }

    console.log(rcData,"rcData")
    console.log(panData,"panData")
    console.log(licenseData,"licenseData")
    console.log(usersdata,"usersdata")
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <h2>{props.isEdit ? "Edit" : "Add"} Driver Profile (Id: {formData?.id})</h2>
                {/* address proof */}
                <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                    <Typography component="h1" variant="h6">
                        Profile Image
                        <br />

                        {
                                <input
                                    type="file"
                                    style={{ marginLeft: 10 }}
                                    name={t('image')}
                                    onChange={(event) => {
                                        setSelectedProfileImage(event.target.files[0]);
                                        if (props.isEdit) {
                                            setLoading(true)
                                            Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'profile_image'))).then(async (res) => {
                                                setLoading(false)
                                                dispatch(fetchUsersOnce());
                                            })
                                        }
                                    }}
                                />
                        }

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {selectedProfile ?
                        <Tooltip title={t('cancel')}>
                            <CancelIcon onClick={() => setSelectedProfileImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                        </Tooltip>
                        : null}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {selectedProfile ?
                        <img alt="not found" height={"200px"} src={URL.createObjectURL(selectedProfile)} style={{ marginTop: 15, marginBottom: 20 }} />
                        :
                        <img alt="img" height={"200px"} src={data?.profile_image ? data?.profile_image : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} style={{ marginTop: 10 }} />
                    }
                    <br />
                </Grid>



                <Grid mt={2} container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            name="driverId"
                            label="Driver Id"
                            value={formData?.driverId}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            name="firstName"
                            label="First Name"
                            value={formData?.firstName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            name="lastName"
                            label="Last Name"
                            value={formData?.lastName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            name="mobile"
                            label="Mobile Number"
                            value={formData?.mobile}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            name="email"
                            label="Email"
                            value={formData?.email}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>Gender</InputLabel>
                            <>
                                {
                                    (formData?.gender) &&
                                    <Select
                                        name="gender"
                                        value={formData?.gender}
                                        onChange={handleInputChange}
                                        displayEmpty

                                    >
                                        <MenuItem
                                            value={'male'}>Male</MenuItem>
                                        <MenuItem
                                            value={"female"}>Female</MenuItem>
                                        <MenuItem
                                            value={"other"}>Other</MenuItem>
                                    </Select>


                                }
                                {
                                    !formData?.hasOwnProperty("gender") &&
                                    <Select
                                        name="gender"
                                        value={formData?.gender}
                                        onChange={handleInputChange}
                                        displayEmpty

                                    >
                                        <MenuItem
                                            value={'male'}>Male</MenuItem>
                                        <MenuItem
                                            value={"female"}>Female</MenuItem>
                                        <MenuItem
                                            value={"other"}>Other</MenuItem>
                                        {/* Add more options as needed */}
                                    </Select>
                                }
                            </>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                                
                        <TextField
                            name="dob"
                            type='text'
                            label="Date of Birth"
                            value={formData?.dob}
                            formate = "mm/dd/yyyy"
                            inputProps={{ max:minDateString }} // Set the minimum date
                            
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="referredBy"
                            label="Referred By"
                            value={formData?.referredBy}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="referredId"
                            label="Referred Id"
                            value={formData?.referredId}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            name="walletBalance"
                            label="Wallet Balance"
                            value={formData?.walletBalance}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>Account Approval</InputLabel>
                            <>
                                {
                                    (formData?.approved == true || formData?.approved == false) &&
                                    <Select
                                        name="approved"
                                        value={formData?.approved}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        renderValue={(value) => {
                                            if (value == true) {
                                                return "Approved";
                                            } else if (value == false) {
                                                return "Not Approved";
                                            }
                                        }}
                                    >
                                        <MenuItem
                                            selected={formData?.approved == true}
                                            value={true}>Approved</MenuItem>
                                        <MenuItem
                                            selected={formData?.approved == false}
                                            value={false}>Not Approved</MenuItem>
                                        {/* Add more options as needed */}
                                    </Select>


                                }
                                {
                                    !formData?.hasOwnProperty("approved") &&
                                    <Select
                                        name="approved"
                                        value={formData?.approved}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        renderValue={(value) => {
                                            if (value == true) {
                                                return "Approved";
                                            } else if (value == false) {
                                                return "Not Approved";
                                            }
                                        }}
                                    >
                                        <MenuItem
                                            value={true}>Approved</MenuItem>
                                        <MenuItem
                                            value={false}>Not Approved</MenuItem>
                                        {/* Add more options as needed */}
                                    </Select>
                                }
                            </>
                        </FormControl>

                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant='standard'>
                            <InputLabel>Car Approval</InputLabel>
                            <>
                                {(formData?.carApproved == true || formData?.carApproved == false) && <Select
                                    name="carApproved"
                                    value={formData?.carApproved}
                                    onChange={handleInputChange}
                                    displayEmpty
                                    renderValue={(value) => {
                                        if (value == true) {
                                            return "Approved";
                                        } else if (value == false) {
                                            return "Not Approved";
                                        }
                                    }}
                                >
                                    <MenuItem
                                        selected={formData?.carApproved == true}
                                        value={true}>Approved</MenuItem>
                                    <MenuItem
                                        selected={formData?.carApproved == false}
                                        value={false}>Not Approved</MenuItem>
                                    {/* Add more options as needed */}
                                </Select>
                                }

                                {
                                    !formData?.hasOwnProperty("carApproved")
                                    &&
                                    <Select
                                        name="carApproved"
                                        value={formData?.carApproved}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        renderValue={(value) => {
                                            if (value == true) {
                                                return "Approved";
                                            } else if (value == false) {
                                                return "Not Approved";
                                            }
                                        }}
                                    >
                                        <MenuItem

                                            value={true}>Approved</MenuItem>
                                        <MenuItem
                                            value={false}>Not Approved</MenuItem>
                                    </Select>

                                }
                            </>
                        </FormControl>
                    </Grid>
                </Grid>



                <h2 style={{ marginTop: "20px" }}>
                    Vehicle Information
                </h2>
                <Grid mt={4} container spacing={2}>

                    <Grid item xs={6} sm={6}>
                        <FormControl variant="standard" fullWidth >
                            <InputLabel>Vehicle Type</InputLabel>
                            <>
                                {
                                    (formData?.car_type) && <Select
                                        name="car_type"
                                        value={formData?.car_type}
                                        onChange={handleInputChange}
                                        displayEmpty
                                    >
                                        {cartypes && cartypes.cars && cartypes.cars.map((car, index) => {
                                            return (
                                                <MenuItem
                                                    selected={formData?.car_type == car.id}
                                                    key={index} value={car.id}>{car.name}</MenuItem>
                                            )
                                        })}
                                    </Select>}
                                {
                                    !formData?.hasOwnProperty("car_type") && <Select
                                        name="car_type"
                                        value={formData?.car_type}
                                        onChange={handleInputChange}
                                        displayEmpty
                                    >
                                        {cartypes && cartypes.cars && cartypes.cars.map((car, index) => {
                                            return (
                                                <MenuItem
                                                    key={index} value={car.id}>{car.name}</MenuItem>
                                            )
                                        })}
                                    </Select>

                                }

                            </>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="vehicleMake"
                            label="Vehicle Make/Brand"
                            value={formData?.vehicleMake}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="vehicleModel"
                            label="Vehicle Model No"
                            value={formData?.vehicleModel}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <TextField
                            name="other_info"
                            label="Vehicle Other Info"
                            value={formData?.other_info}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>




                </Grid>
                <h2 style={{ marginTop: "20px" }}>
                    Registartion Certificate
                </h2>
                <Grid mt={2} container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name="vehicleNumber"
                            label="Vehicle Registration Number"
                            value={formData?.vehicleNumber}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            mt={4}
                            variant="contained"
                            color={formData?.valid_rc || (rcData && rcData?.valid_rc) ? "success" : "primary"}

                            onClick={() => {
                                if (formData?.vehicleNumber) {
                                    setLoading(true)
                                    Promise.resolve(dispatch(rcVerification({
                                        source: formData?.vehicleNumber,
                                        source_type: "id",
                                        uuid: data?.id,

                                    }))).then(async (res) => {
                                        // setVerifyPan(true)
                                        setLoading(false)
                                        console.log(res, "res")
                                        setFormData({
                                            ...formData,
                                            valid_rc: rcData?.valid_rc,
                                        })
                                        // updatePan()

                                    })
                                } else {
                                    alert("Please enter id number")
                                }
                            }}
                        >
                            Verify
                        </Button>
                    </Grid>
                </Grid>


                <Grid mt={2} container spacing={2}>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography><b>Engine Number:</b> {formData?.rc_engine_co || (rcData && rcData?.validated_data?.vehicle_engine_number)}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography><b>Chasis Number:</b> {formData?.rc_chasis_no || (rcData && rcData?.validated_data?.vehicle_chasi_number)}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography><b>Fuel Type:</b> {formData?.rc_fuel_type || (rcData && rcData?.validated_data?.fuel_type)}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography><b>Pollution Expiry:</b> {formData?.rc_pollution_expiry || (rcData && rcData?.validated_data?.pucc_upto)}</Typography>
                    </Grid>


                </Grid>


                <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                    <Typography component="h1" variant="h6">
                        RC Front
                        <br />
                    
                                <input
                                    type="file"
                                    style={{ marginLeft: 10 }}
                                    name={t('image')}
                                    onChange={(event) => {
                                        setSelectedRCFrontImage(event.target.files[0]);
                                        if (props.isEdit) {
                                            setLoading(true)
                                            Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'rcFrontImage'))).then(async (res) => {
                                                setLoading(false)
                                                dispatch(fetchUsersOnce());
                                            })
                                        }
                                    }}
                                />
                    </Typography>
                    {rcFrontImage ?
                        <Tooltip title={t('cancel')}>
                            <CancelIcon onClick={() => setSelectedRCFrontImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                        </Tooltip>
                        : null}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {rcFrontImage ?
                        <img alt="not found" height={"200px"} src={URL.createObjectURL(rcFrontImage)} style={{ marginTop: 15, marginBottom: 20 }} />
                        :
                        <img alt="img" height={"200px"} src={data?.rcFrontImage ? data?.rcFrontImage : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} style={{ marginTop: 10 }} />
                    }
                    <br />
                </Grid>

                <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                    <Typography component="h1" variant="h6">
                        RC  Back
                        <br />
                        {
                                <input
                                    type="file"
                                    style={{ marginLeft: 10 }}
                                    name={t('image')}
                                    onChange={(event) => {
                                        setSelectedRCBackImage(event.target.files[0]);
                                        if (props.isEdit) {
                                            setLoading(true)
                                            Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'rcBackImage'))).then(async (res) => {
                                                setLoading(false)
                                                dispatch(fetchUsersOnce());
                                            })
                                        }
                                    }}
                                />
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {rcBackImage ?
                        <Tooltip title={t('cancel')}>
                            <CancelIcon onClick={() => setSelectedRCBackImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                        </Tooltip>
                        : null}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {rcBackImage ?
                        <img alt="not found" height={"200px"} src={URL.createObjectURL(rcBackImage)} style={{ marginTop: 15, marginBottom: 20 }} />
                        :
                        <img alt="img" height={"200px"} src={data?.rcBackImage ? data?.rcBackImage : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} style={{ marginTop: 10 }} />
                    }
                    <br />
                </Grid>


                <Grid mt={4} container spacing={2}>
                    <Grid item xs={6}>
                        <h2 style={{ marginTop: "20px" }}>
                            Address Proof Document
                        </h2>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="VoterId"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="Adhar" control={<Radio />} label="Adhar" />
                                <FormControlLabel value="VoterId" control={<Radio />} label="VoterId" />
                            </RadioGroup>
                        </FormControl>
                    </Grid> */}
                </Grid>
                <Grid mt={4} container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name="documentNo"
                            label="Document No"
                            value={formData?.documentNo}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            mt={4}
                            variant="contained"
                            color={formData?.valid_aadhaar || (adharData && adharData?.result?.valid_aadhaar) ? "success" : "primary"}

                            onClick={() => {
                                if (formData?.documentNo) {
                                    setLoading(true)
                                    Promise.resolve(dispatch(aadhaarXMLVerification({
                                        source: formData?.documentNo,
                                        source_type: "id",

                                    }))).then(async (res) => {
                                        setLoading(false)
                                        console.log(res, "res")
                                    })
                                } else {
                                    alert("Please enter id number")
                                }
                            }}
                        >
                            Verify
                        </Button>
                    </Grid>

                </Grid>

                <Grid mt={2} container spacing={2}>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography><b>Aadhar Name:</b> {formData?.aadhaar_full_name ||  (adharData && adharData?.data?.result?.validated_data?.full_name)}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography><b>Aadhar DOB:</b> {formData?.aadhaar_dob ||  (adharData && adharData?.data?.result?.validated_data?.dob)} </Typography>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography><b>House No:</b> {formData?.aadhaar_house ||  (adharData && adharData?.data?.result?.validated_data?.address?.house)} {formData?.aadhaar_loc ||  (adharData && adharData?.data?.result?.validated_data?.address?.loc)} {formData?.aadhaar_street ||  (adharData && adharData?.data?.result?.validated_data?.address?.street)} {formData?.aadhaar_state ||  (adharData && adharData?.data?.result?.validated_data?.address?.state)} {formData?.aadhaar_zip ||  (adharData && adharData?.data?.result?.validated_data?.zip)}   </Typography>
                    </Grid>
                    {/* address proof */}
                    <Grid mt={2} item xs={6} sm={6} md={6} lg={6}>
                        <Typography component="h1" variant="h6">
                            Address Proof Front
                            <br />
                            {
                                    <input
                                        type="file"
                                        style={{ marginLeft: 10 }}
                                        name={t('image')}
                                        onChange={(event) => {
                                            setSelectedAddressProofFrontImage(event.target.files[0]);
                                            if (props.isEdit) {
                                                setLoading(true)
                                                Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'addressProofFrontImage'))).then(async (res) => {
                                                    setLoading(false)
                                                    dispatch(fetchUsersOnce());
                                                })
                                            }
                                        }}
                                    />
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFirstImage ?
                            <Tooltip title={t('cancel')}>
                                <CancelIcon onClick={() => setSelectedAddressProofFrontImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                            </Tooltip>
                            : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {addressProofFrontImage ?
                            <img alt="not found" height={"200px"} src={URL.createObjectURL(addressProofFrontImage)} style={{ marginTop: 15, marginBottom: 20 }} />
                            :
                            <img alt="img" height={"200px"} src={data?.addressProofFrontImage ? data?.addressProofFrontImage : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} style={{ marginTop: 10 }} />
                        }
                        <br />
                    </Grid>

                    <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                        <Typography component="h1" variant="h6">
                            Address Proof Back
                            <br />
                            {
                                    <input
                                        type="file"
                                        style={{ marginLeft: 10 }}
                                        name={t('image')}
                                        onChange={(event) => {
                                            setSelectedAddressProofBackImage(event.target.files[0]);
                                            if (props.isEdit) {
                                                setLoading(true)
                                                Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'addressProofBackImage'))).then(async (res) => {
                                                    setLoading(false)
                                                    dispatch(fetchUsersOnce());
                                                })
                                            }
                                        }}
                                    />
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        {addressProofBackImage ?
                            <Tooltip title={t('cancel')}>
                                <CancelIcon onClick={() => setSelectedAddressProofBackImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                            </Tooltip>
                            : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        {addressProofBackImage ?
                            <img alt="not fount" height={"200px"} src={URL.createObjectURL(addressProofBackImage)} style={{ marginTop: 15, marginBottom: 20 }} />
                            :
                            <img alt="img" height={"200px"} src={data?.addressProofBackImage ? data?.addressProofBackImage : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} style={{ marginTop: 10 }} />
                        }
                        <br />
                    </Grid>
                </Grid>

                <h2 style={{ marginTop: "20px" }}>
                    ID/ PAN No Document
                </h2>

                <Grid mt={2} container spacing={2}>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography><b>PAN Name:</b> {formData?.pan_name}</Typography>
                    </Grid>


                </Grid>
                <Grid mt={4} container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <TextField
                            name="verifyId"
                            label="PAN No"
                            value={formData?.verifyId}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1} lg={1}>
                        <Button
                            mt={4}
                            variant="contained"
                            color={formData?.valid_pan || (panData && panData?.valid_pan) ? "success" : "primary"}
                            onClick={() => {
                                if (formData?.verifyId) {
                                    setLoading(true)
                                    Promise.resolve(dispatch(panVerification({
                                        source: formData?.verifyId,
                                        source_type: "id",
                                        uuid: data?.id,
                                        firstName: formData?.firstName,
                                    }))).then(async (res) => {
                                        setLoading(false)
                                    })
                                } else {
                                    alert("Please enter id number")
                                }
                            }}
                        >
                            Verify
                        </Button>
                    </Grid>



                    <Grid mt={2} item xs={12} sm={6} md={6} lg={6}>
                        <Typography component="h1" variant="h6">
                            PAN No Image
                            <br />
                            {
                                    <input
                                        type="file"
                                        style={{ marginLeft: 10 }}
                                        name={t('image')}
                                        onChange={(event) => {
                                            setSelectedImage(event.target.files[0]);
                                            if (props.isEdit) {
                                                setLoading(true)
                                                Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'verifyIdImage'))).then(async (res) => {
                                                    setLoading(false)
                                                    dispatch(fetchUsersOnce());
                                                })
                                            }
                                        }}
                                    />
                            }
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            mt={4}
                            variant="contained"
                            color="primary"

                            onClick={() => {
                                if (props.isEdit) {
                                    setLoading(true)
                                    Promise.resolve(dispatch(panVerification({
                                        source: getBase64(formData?.verifyIdImage),
                                        source_type: "base64",
                                        uuid: data?.id

                                    }))).then(async (res) => {
                                        setLoading(false)
                                        dispatch(fetchUsersOnce());
                                    })
                                } else {
                                    alert("Please enter image")
                                }
                            }}
                        >
                            Verify
                        </Button>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedImage ?
                            <Tooltip title={t('cancel')}>
                                <CancelIcon onClick={() => setSelectedImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                            </Tooltip>
                            : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedImage ?
                            <img alt="not fount" height={"200px"} src={URL.createObjectURL(selectedImage)} style={{ marginTop: 15, marginBottom: 20 }} />
                            :
                            <img alt="img" id="vImage" height={"200px"} src={data?.verifyIdImage ? data?.verifyIdImage : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} style={{ marginTop: 10 }} />
                        }
                        <br />
                    </Grid>
                </Grid>

                <h2 style={{ marginTop: "20px" }}>
                    Driver License Document
                </h2>
                <Grid mt={4} container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name="drivingLicenseNo"
                            label="Driving License No"
                            value={formData?.drivingLicenseNo}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            mt={4}
                            variant="contained"
                            color={formData?.valid_dl || (licenseData && licenseData?.valid_dl ) ? "success" : "primary"}


                            onClick={() => {
                                setLoading(true)
                                Promise.resolve(dispatch(licenseVerification({
                                    source: formData?.drivingLicenseNo,
                                    source_type: "id",
                                    dob: formData?.dob,
                                    uuid: data?.id,
                                    firstName: formData?.firstName,


                                }))).then(async (res) => {
                                    setLoading(false)
                                    console.log(res, "res")



                                })

                            }}
                        >
                            Verify
                        </Button>
                    </Grid>
                </Grid>

                <Grid mt={2} container spacing={2}>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography><b>DL Name:</b> {formData?.dl_name || (licenseData && licenseData?.validated_data?.name )}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography><b>DL Expiry:</b> {formData?.dl_doe || (licenseData && licenseData?.validated_data?.doe )}</Typography>
                    </Grid>

                </Grid>


                <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                    <Typography component="h1" variant="h6">
                        Driving License Front
                        <br />
                        {
                                <input
                                    type="file"
                                    style={{ marginLeft: 10 }}
                                    name={t('image')}
                                    onChange={(event) => {
                                        setSelectedDrivingLicenseFirstImage(event.target.files[0]);
                                        if (props.isEdit) {
                                            setLoading(true)
                                            Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'licenseImage'))).then(async (res) => {
                                                setVerifyPan(true)
                                                dispatch(fetchUsersOnce());
                                                setLoading(false)
                                                console.log(res, "res")
                                                updatePan()
                                            })

                                        }
                                    }}
                                />
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {selectedFirstImage ?
                        <Tooltip title={t('cancel')}>
                            <CancelIcon onClick={() => setSelectedDrivingLicenseFirstImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                        </Tooltip>
                        : null}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {selectedFirstImage ?
                        <img alt="not fount" height={"200px"} src={URL.createObjectURL(selectedFirstImage)} style={{ marginTop: 15, marginBottom: 20 }} />
                        :
                        <img alt="img" height={"200px"} src={data?.licenseImage ? data?.licenseImage : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} style={{ marginTop: 10 }} />
                    }
                    <br />
                </Grid>

                <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                    <Typography component="h1" variant="h6">
                        Driving License Back
                        <br />
                        {
                                <input
                                    type="file"
                                    style={{ marginLeft: 10 }}
                                    name={t('image')}
                                    onChange={(event) => {
                                        setSelectedDrivingLicenseBackImage(event.target.files[0]);
                                        if (props.isEdit) {
                                            setLoading(true)
                                            Promise.resolve(dispatch(updateLicenseImage(formData?.id?.toString(), event.target.files[0], 'licenseImageBack'))).then(async (res) => {
                                                setLoading(false)
                                                dispatch(fetchUsersOnce());
                                            })
                                        }
                                    }}
                                />
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {selectedBackImage ?
                        <Tooltip title={t('cancel')}>
                            <CancelIcon onClick={() => setSelectedDrivingLicenseBackImage(null)} style={{ fontSize: 30, backgroundColor: 'red', borderRadius: 50, color: "white" }} />
                        </Tooltip>
                        : null}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {selectedBackImage ?
                        <img alt="not fount" height={"200px"} src={URL.createObjectURL(selectedBackImage)} style={{ marginTop: 15, marginBottom: 20 }} />
                        :
                        <img alt="img" height={"200px"} src={data?.licenseImageBack ? data?.licenseImageBack : data?.licenseImageBack} style={{ marginTop: 10 }} />
                    }
                    <br />
                </Grid>

                <h2 style={{ marginTop: "20px" }}>
                    Bank Account Details
                </h2>
                <Grid mt={2} item xs={12} sm={12} md={12} lg={12}>
                    <Typography component="h1" variant="h6">
                        Account Number

                    </Typography>
                    <TextField
                        name="bankAccount"
                        label="Account Number"
                        value={formData?.bankAccount}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                    <Typography component="h1" variant="h6">
                        Bank Name
                    </Typography>
                    <TextField
                        name="bankName"
                        label="Bank Name"
                        value={formData?.bankName}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid mt={2} item xs={12} sm={12} md={6} lg={6}>
                    <Typography component="h1" variant="h6">
                        IFSC Code
                    </Typography>
                    <TextField
                        name="bankCode"
                        label="IFSC Code"
                        value={formData?.bankCode}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={2}
                    style={{ marginTop: "10px" }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        sx={{
                            marginRight: "10px"
                        }}
                        onClick={onClose}>Close</Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleSave}>Save</Button>
                </Grid>
                
                <AlertDialog
                    open={adharData?.open}
                    onClose={() => {
                        dispatch(closeAlert())
                    }}
                    title={"Adhar Verification"}
                    message={t('successMessage')}
                >
                    {/* add lable and input field */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontSize: 15
                                }}>
                                We just sent an OTP to your mobile number linked with your Aadhaar Card.
                                <br>
                                </br>
                                Enter OTP
                            </Typography>
                            <TextField
                                name="otp"
                                label="OTP"
                                value={formData?.otp}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p>Resend in : {timerInterval} </p>
                        </Grid>
                        <Grid sx={{
                            textAlign: "right"
                        }} item xs={6}>
                            <p style={{
                                cursor: "pointer"
                            }} onClick={() => {
                                if (formData?.documentNo) {
                                    setLoading(true)
                                    Promise.resolve(dispatch(aadhaarXMLVerification({
                                        source: formData?.documentNo,
                                        source_type: "id",
                                        uuid: data?.id,
                                        firstName: formData?.firstName,

                                    }))).then(async (res) => {
                                        setLoading(false)
                                        console.log(res, "res")
                                        dispatch(fetchUsersOnce());
                                    }).catch((err) => {
                                        console.log(err)
                                        setLoading(false)
                                    })
                                } else {
                                    alert("Please enter id number")
                                }
                            }}>Resend OTP</p>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    dispatch(aadhaarOTP({
                                        otp: formData?.otp,
                                        reference_id: adharData?.data?.reference_id,
                                        transaction_id: adharData?.data?.transaction_id,
                                        uuid: data?.id
                                    }))
                                }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>


                </AlertDialog>
            </Box>
        </Modal>
    );
};

export default EditModal;