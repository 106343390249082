import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Career(props) {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const { ...rest } = props;

    return (
        <div style={{ margin: '-8px' }}>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: 'white',
                }}
                {...rest}
            />
            {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

            {/* Hero Section */}
            <div id="sidebox-overlay"></div>
            <section class="page-header">
                <div class="page-header-shape"></div>
                <div class="container">
                    <div class="page-header-info">
                        <h4>About Us!</h4>
                        <h2>
                            Feel your journey <br /> with <span>GoBabyGo!</span>
                        </h2>
                        <p>
                            Everything your taxi business <br />
                            needs is already here!{' '}
                        </p>
                    </div>
                </div>
            </section>

            <div style={{ margin: 20 }}>
                <div className={classes.container} style={{ padding: '5% 0' }}>
                    <h2>Career</h2>
                    <h3>Join our team</h3>
                    <p style={{ color: '#666666', padding: 0 }}>
                       Do you excel in an inventive, quick-paced environment? Do you feel strongly about changing how people perceive transportation? If so, GoBabyGo Cabs might be the place where you meet your ideal match! GoBabyGo Cabs, a pioneering force in the transportation sector, is more than simply a business; it's a vibrant platform that presents stimulating job prospects in a variety of fields.
                    </p>
                    <h3>Company Overview:</h3>
                    <p style={{ color: '#666666', padding: 0 }}>
                       GoBabyGo Cabs is a company that offers more than just ride-hailing. It is a technologically advanced transit system that is revolutionizing how people get from point A to point B. GoBabyGo Cabs has become a market leader by placing a priority on sustainability, convenience, and safety while offering drivers the chance to participate in a ground-breaking new venture.
                    </p>
                    <h3>Why Choose GoBabyGo Cabs:</h3>
                   <p style={{ color: '#666666', padding: 0 }}>
<b>1. Innovation at Its Core:</b>Innovation is a way of life at GoBabyGo Cabs, not simply a trendy term. The business is dedicated to keeping on the cutting edge of technology, offering cutting-edge app features and cutting-edge driver-partner support systems. You'll have the chance to work on initiatives that reimagine the future of transport if you join the team.
<br/><br/><b>2. Diverse Opportunities: :</b>GoBabyGo Cabs offers a wide range of chances in a variety of roles, whether you're a tech enthusiast, marketing whiz, financial prodigy, or operations whiz. Diverse skill sets and backgrounds are valued by the organization because it understands that success and innovation are fueled by variety.
<br/><br/><b>3. Impactful Work: :</b>The future of transport is shaped by every member of the GoBabyGo Cabs team. Your contributions won't simply be duties; they'll be significant strides toward making travel for people safer, more effective, and more pleasurable.
<br/><br/><b>4. Professional Growth: :</b> Your career can develop as GoBabyGo Cabs widens its geographic reach. The organization offers opportunities for career advancement, mentorship, and skill development, ensuring that your professional journey is both fruitful and satisfying.
</p>

                    <h3>Current Openings:</h3>
                    <p style={{ color: '#666666', padding: 0 }}>
                        Curious about the roles available at GoBabyGo Cabs? Here's a glimpse of some positions you might find:
<a href="https://www.linkedin.com/company/gobabygocabs/" target="_blank"> https://www.linkedin.com/company/gobabygocabs/</a>

                    </p>
                    <h3>Join the Movement:</h3>
                    <p style={{ color: '#666666', padding: 0 }}>
                       Join GoBabyGo Cabs for a career trajectory that blends innovation, impact, and advancement. There is a place for you on our team, whether you are a recent graduate hoping to enter a dynamic business or a seasoned professional searching for a new challenge.
 

                    </p>
                    <h3>Community Building</h3>
                    <p style={{ color: '#666666', padding: 0 }}>
                      Visit our careers page at [insert link] to look through available positions and discover more about life at GoBabyGo Cabs. Come with us as we rethink transit for the future. The beginning of your career and your future lies here.
                    </p>
                    
                </div>

                {/* <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              position: 'relative',
              marginTop: '30px',
              minHeight: '32px',
              color: '#383838',
              textDecoration: 'none',
            }}
          >
            {t('about_us')}
          </h2>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Welcome to Gobabygo, the future of transportation! We are a
            revolutionary transportation startup that seeks to revolutionise how
            people travel by offering a cutting-edge app-based platform for
            customers to hail rides and drivers to conveniently charge fares and
            accept payments. Our fundamental objective is to build a sustainable
            ecology that is advantageous to both drivers and passengers and that
            is accessible and affordable to everyone.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            At gobabygo, we think that drivers should be empowered and given the
            chance to make some decent money. We have developed a mechanism that
            drastically lowers the commission costs paid by drivers, allowing
            them to keep a bigger share of their earnings in contrast to
            conventional ride-hailing platforms that charge high commissions. We
            hope to help them support their way of life and enhance their
            general quality of life by doing this.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            One of our key strategies for achieving sustainability and
            affordability is the implementation of a subscription-based model.
            This innovative approach enables passengers to enjoy the benefits of
            our services at a reduced cost. We aim to make sure that everyone,
            regardless of their financial situation, has access to safe and
            cheap transportation.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Our dedication to serving tier 2 and tier 3 cities and linking the
            entire country sets us apart from our rivals. We truly think that
            access to transport services should not just be restricted to
            metropolitan areas, but also extend to residents of smaller cities
            and villages. By extending our service to these areas, we hope to
            close the transportation gap and offer practical mobility solutions
            to previously underserved groups.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            We also take great pride in our commitment to environmental
            responsibility and sustainability. To help the environment become
            cleaner and greener and reduce carbon emissions, we actively urge
            drivers to choose eco-friendly vehicles. Our goal is to
            simultaneously improve the current transport system and make it more
            sustainable for coming generations.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Gobabygo is not just a transportation company; it is a movement
            towards a better future. We envision a world where transportation is
            efficient, affordable, sustainable, and accessible to all. Join us
            on this journey as we revolutionize the transportation industry, one
            ride at a time. Together, we can create a connected nation where no
            city is left behind.
          </p>
          <br />
        </div> */}
            </div>

            <Footer />
        </div>
    );
}
