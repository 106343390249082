import React from "react";
import { Button } from "@mui/material";
import comment1Img from "../assets/img/comment-1.png"
import experienceTextRound from "../assets/img/experience-text-round.png"

export default function AboutOurCompany() {
    return (
        <section class="about-section padding" >
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="about-img">
                            <img class="about-img1 wow fade-in-left" data-wow-delay="200ms" src="assets/img/about-1.png" alt="img" />
                            <img class="about-img2 wow fade-in-bottom" data-wow-delay="400ms" src="assets/img/about-2.png" alt="img" />
                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="section-heading mb-40 wow fade-in-right" data-wow-delay="200ms">
                            <h4><span></span>About Our Company</h4>
                            <h3>Empowering Drivers, Zero Commission!</h3>
                            <p>At GoBabyGo Cabs, we believe that the foundation of a successful ride-hailing platform lies in empowering its drivers. Our mission is to revolutionize the way drivers and riders experience ride-hailing services. Established with a vision to create a fair and supportive ecosystem, GoBabyGo Cabs is committed to providing drivers with the tools and opportunities they need to thrive in the economy, all while ensuring that they receive 100% of their earnings.</p>
                        </div>

                        <ul class="about-info wow fade-in-right" data-wow-delay="400ms">
                            <li>
                                <img class="owner-thumb" src={comment1Img} alt="thumb" />
                                <div class="owner" style={{ marginLeft: '20px' }}>
                                    <h4>Founder - CEO</h4>
                                    <Button href='/about-ceo' target='_blank' style={{
                                        backgroundColor: '#f90', clipPath: 'polygon(0 0,100% 0%,90% 100%,0% 100%)', borderRadius: '2px', fontSize: '15px', height: '35px', padding: '0 23px 0 20px', color: 'white', marginTop: '10px'
                                    }}>Read More</Button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section >
    )
}
