import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';
import CollaborationImg from "../assets/img/attachmentcenter.jpg"
import { api } from 'common';
import { useDispatch } from 'react-redux';
 

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Collaboration(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;
    const {addCollaboration} = api;
    const dispatch = useDispatch();
  const handleSubmit = async(e) => {
    e.preventDefault();
    // get values
    const name = e.target[0].value;
    const contact = e.target[1].value;
    const email = e.target[2].value;
    const address = e.target[3].value;
    const city = e.target[4].value;
    const pincode = e.target[5].value;
    const createdAt = new Date().getTime();

    // send values to backend
    const data = {
      name,
      contact,
      email,
      address,
      city,
      pincode,
      createdAt
    };

    dispatch(addCollaboration(data)).then((res) => {
      // clear form
      e.target[0].value = '';
      e.target[1].value = '';
      e.target[2].value = '';
      e.target[3].value = '';
      e.target[4].value = '';
      e.target[5].value = '';

    alert('Thank you for your interest. We will get back to you soon.');

    });

  };
  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>About Attachment Center!</h4>
            <h2>
              Feel your journey <br /> with <span>GoBabyGo!</span>
            </h2>
            <p>
              Everything your taxi business <br />
              needs is already here!{' '}
            </p>
          </div>
        </div>
      </section>

 <div class="container">
 <div className={classes.container} style={{ padding: '5% 20' }}>
<h2>Attachment Center</h2>
</div>
<div class="row">

<div class="col-md-8">

<img src={CollaborationImg} alt="CollaborationImg" />
 </div>
 
 <div class="col-md-4">
 <div class="contact-title">
<h2>Fill the Details</h2>
<p>Get in touch to discuss about attachment center. Please fill out the form below.</p>
</div>


<form onSubmit={handleSubmit}>
    <input type="text" placeholder="Enter Name" className="attachment-input" />
    
    <input type="text" placeholder="Enter Contact No" className="attachment-input" />
    
    <input type="email" placeholder="Enter Email Id" className="attachment-input" />
    
    <input type="text" placeholder="Enter Address" className="attachment-input" />
    
    <input type="text" placeholder="Enter City" className="attachment-input" />
    
    <input type="text" placeholder="Enter Pincode" className="attachment-input" />
    <button id="submit" className="default-btn" type="submit">Send Massage</button>
</form>

 </div>
</div>

<div class="row">

<div class="col-md-12">
<center>
<h1>Be a part of the REVOLUTION!</h1>

<h2>Join us now</h2>
</center>
</div>
</div>
 

<div class="row">

<div class="col-md-3">
 <button  className="attach-btn">Revenue Share</button>
</div>

<div class="col-md-9">
 <h3 class="attach-text">Our partnership model ensures a fair revenue share, benefiting both parties for growth and prosperity.</h3>
</div>
<br/><br/><br/>

<div class="col-md-3">
 <button  className="attach-btn">Welcome Kit</button>
</div>

<div class="col-md-9">
 <h3 class="attach-text">We extend a warm welcome to drivers with a comprehensive Welcome Kit, equipping them with the tools and information they need for a successful journey with us.</h3>
</div>

<div class="col-md-3">
 <button className="attach-btn">Great ROI</button>
</div>

<div class="col-md-9">
<h3 class="attach-text">Our investment in technology and customer service guarantees a great return on investment for your business, driving profitability and long-term success.</h3>
</div>

<div class="col-md-3">
 <button  className="attach-btn">Helping Drivers</button>
</div>

<div class="col-md-9">
 <h3 class="attach-text">Our commitment to driver welfare goes beyond words; we actively support and empower drivers to thrive in their roles and careers.</h3>
</div>

<div class="col-md-3">
 <button  className="attach-btn">100% Support</button>
</div>

<div class="col-md-9">
 <h3 class="attach-text">Our commitment to driver welfare goes beyond words; we actively support and empower drivers to thrive in their roles and careers.</h3>
</div>

<div class="col-md-3">
 <button  className="attach-btn">Good Incentives</button>
</div>

<div class="col-md-9">
 <h3 class="attach-text">When you connect drivers with us, you'll earn a generous commission It's a great opportunity to earn extra income by helping us expand our network of drivers, and the more drivers you bring in, the more you can earn.</h3>
</div>


</div>
 
</div>
  
      <Footer />
    </div>
  );
}
