import {
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_ALL_USERS_FAILED,
    FETCH_ALL_USERS_STATIC,
    FETCH_ALL_USERS_STATIC_SUCCESS,
    FETCH_ALL_USERS_STATIC_FAILED,
    EDIT_USER,
    FETCH_ALL_DRIVERS,
    FETCH_ALL_DRIVERS_SUCCESS,
    FETCH_ALL_DRIVERS_FAILED,
    FETCH_ADHAR_DATA,
    FETCH_FEEDBACK_DATA,
    FETCH_PAN_DATA,
    FETCH_RC_DATA,
    FETCH_LICENSE_DATA
} from "../store/types";

export const INITIAL_STATE = {
    users: null,
    staticusers: null,
    feedBacks: null,
    drivers: null,
    adharData: null,
    panData: null,
    rcData: null,
    licenseData: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const usersreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS:
            return {
                ...state,
            };
        case FETCH_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
            };
        case FETCH_ALL_USERS_FAILED:
            return {
                ...state,
                users: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_ALL_DRIVERS:
            return {
                ...state
            };
        case FETCH_ALL_DRIVERS_SUCCESS:
            return {
                ...state,
                drivers: action.payload
            };
        case FETCH_ALL_DRIVERS_FAILED:
            return {
                ...state,
                drivers: null,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case FETCH_ALL_USERS_STATIC:
            return {
                ...state,
                loading: true
            };
        case FETCH_ALL_USERS_STATIC_SUCCESS:
            return {
                ...state,
                staticusers: action.payload,
                loading: false
            };
        case FETCH_ALL_USERS_STATIC_FAILED:
            return {
                ...state,
                staticusers: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
            // fetch adhar data no way
        case FETCH_ADHAR_DATA:
            return {
                ...state,
                adharData: action.payload,
                loading: true
            };
            // fetch pan data
        case FETCH_PAN_DATA:
            return {
                ...state,
                panData: action.payload,
                loading: true
            };
            // fetch rc data
        case FETCH_RC_DATA:
            return {
                ...state,
                rcData: action.payload,
                loading: true
            };
            // fetch license data
        case FETCH_LICENSE_DATA:
            return {
                ...state,
                licenseData: action.payload,
                loading: true
            };
        case FETCH_FEEDBACK_DATA:
            return {
                ...state,
                feedBacks: action.payload,
                loading: true
            };
        case EDIT_USER:
            return state;
        default:
            return state;
    }
};