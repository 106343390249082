export const FirebaseConfig = {
	"projectId": "gobabygocab-77701",
	"appId": "1:981841012318:web:594899381f2a31f162f6cf",
	"databaseURL": "https://gobabygocab-77701-default-rtdb.firebaseio.com",
	"storageBucket": "gobabygocab-77701.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCZxvTgt9H-kNfRlf6-kbU9sBwddW03XtI",
	"authDomain": "gobabygocab-77701.firebaseapp.com",
	"messagingSenderId": "981841012318",
	"measurementId": "G-0HG75GHQQ9"
};