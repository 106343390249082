import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const displayClass = isVisible ? 'd-block' : 'd-none';
  const stickyClass = isSticky ? '' : 'd-none';


  const stickyStyle = {
    backgroundColor: 'white',
    position: 'fixed',
    top: '0px',
    width: '100%',
    zIndex: 1000
  };

  useEffect(() => {
    // For responsive classes
    const updateViewportClass = () => {
      if (window.innerWidth < 992) {
        document.body.classList.remove('viewport-lg');
        document.body.classList.add('viewport-sm');
      } else {
        document.body.classList.remove('viewport-sm');
        document.body.classList.add('viewport-lg');
      }
    };

    // For sticky header
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('resize', updateViewportClass);
    window.addEventListener('scroll', handleScroll);

    // Initial setup
    updateViewportClass();

    return () => {
      window.removeEventListener('resize', updateViewportClass);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);





  return (
    <>
      <header className="main-header">
        <div className="top-header">
          <div className="container">
            <div className="top-header-wrap">
              <div className="top-left">
                <p>Reliable Taxi Service & Transport Solutions!</p>
              </div>
              <div className="top-right">
                <ul className="header-social-share">
                  <li>
                    <a
                      href="https://www.facebook.com/gobabygocabs/"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/gobabygocabs" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/gobabygocabs/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/gobabygocabs/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
  <li>
                    <a
                      href="https://www.youtube.com/@gobabygocabs"
                      target="_blank"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mid-header">
          <div className="container">
            <div className="mid-header-wrap">
              <div className="site-logo">
                <Link to={'/'}>
                  <img
                    src="assets/img/logo-dark.png"
                    alt="Logo"
                    className="website-logo"
                  />
                </Link>
              </div>
              <ul className="header-info">
                <li>
                  <div className="header-info-icon">
                    <i className="las la-phone-volume"></i>
                  </div>
                  <div className="header-info-text">
                    <h3>
                      <span>Call us now</span>
                      <a href="tel:+919873115145">+91-9873115145</a>
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="header-info-icon">
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div className="header-info-text">
                    <h3>
                      <span>Email now</span>
                      <a style={{ textTransform: 'lowercase' }} href="mailto:info@gobabygocabs.com">info@gobabygocabs.com</a>
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="header-info-icon">
                    <i className="las la-map-marked-alt"></i>
                  </div>
                  <div className="header-info-text">
                    <h3>
                      <span>Address</span>Sector-3 Noida 201301
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="nav-menu-wrapper">
          <div className="container">
            <div className="nav-menu-inner">
              <div className="site-logo">
                <a href="/">
                  <img
                    src="assets/img/logo-dark.png"
                    alt="Logo"
                    className="website-logo"
                  />
                </a>
              </div>
              <div className={`header-menu-wrap ${displayClass} d-lg-block`}>
                <ul className="nav-menu">
                  <li className="dropdown_menu" style={{ color: 'white !important' }}>
                    <Link to={"/"} style={{ color: 'white !important' }}>Home</Link>
                    {/* <ul>
                    <li>
                      <a href="index.html">Home Default</a>
                    </li>
                    <li>
                      <a href="index-2.html">Home Modern</a>
                    </li>
                  </ul> */}
                  </li>
                  <li className="dropdown_menu">
                    <Link to={"/"}>Company</Link>
                    <ul>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to={"/about-ceo"}>About CEO</Link>
                      </li>
                      <li>
                        <Link to={"/ceo-message"}>CEO Message</Link>
                      </li>
                      <li>
                        <Link to={"/our-core-values"}>Our Core Values</Link>
                      </li>
 			<li>
                        <Link to={"/faq"}>FAQ</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown_menu">
                    <Link to={"/safety"}>Safety</Link>
                  </li>
                  <li className="dropdown_menu">
                    <Link to={"/career"}>Career</Link>
                  </li>
                  <li className="dropdown_menu">
                    <a href={"https://blog.gobabygocabs.com/"} target='_blank'>Blog</a>
                  </li>
		 <li>
                    <Link to={"/collaboration"}>Authorized Centre</Link>
                  </li>
                  <li>
                    <Link to={"/contact-us"}>Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="menu-right-item">
                <Link to={"/login"} className="menu-btn">
                  Log In / Sign Up
                </Link>
              </div>
              <div className="mobile-menu-icon" onClick={toggleMenu}>
                <div className="burger-menu">
                  <div className="line-menu line-half first-line"></div>
                  <div className="line-menu"></div>
                  <div className="line-menu line-half last-line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>


      <div className={` ${stickyClass} d-flex justify-content-around align-items-center pt-3 pb-3`} style={stickyStyle}>
        <div className="site-logo">
          <Link to={'/'}>
            <img
              src="assets/img/logo-dark.png"
              alt="Logo"
              className="website-logo"
            />
          </Link>
        </div>
        <div className={`header-menu-wrap ${displayClass} d-lg-block`}>
          <ul className="nav-menu">
            <li className="active dropdown_menu">
              <Link to={"/"} style={{ color: 'black' }}>Home</Link>
            </li>
            <li className="dropdown_menu">
              <Link to={"/"} style={{ color: 'black' }}>Company</Link>
              <ul >
                <li>
                  <Link to="/about-us" style={{ color: 'black' }}>About Us</Link>
                </li>
                <li>
                  <Link to={"/about-ceo"} style={{ color: 'black' }}>About CEO</Link>
                </li>
                <li>
                  <Link to={"/ceo-message"} style={{ color: 'black' }}>CEO Message</Link>
                </li>
                <li>
                  <Link to={"/our-core-values"} style={{ color: 'black' }}>Our Core Values</Link>
                </li>
 		<li>
                  <Link to={"/faq"} style={{ color: 'black' }}>FAQ</Link>
                </li>
              </ul>
            </li>
            <li className="dropdown_menu">
              <Link to={"/safety"} style={{ color: 'black' }}>Safety</Link>
            </li>
            <li className="dropdown_menu">
              <Link to={"/career"} style={{ color: 'black' }}>Career</Link>
            </li>
            <li className="dropdown_menu">
              <a href={"https://blog.gobabygocabs.com/"} target='_blank' style={{ color: 'black' }}>Blog</a>
            </li>
 		 
 <li className="dropdown_menu">
              <Link to={"/collaboration"} style={{ color: 'black' }}>Authorized Centre</Link>
            </li>


            <li>
              <Link to={"/contact-us"} style={{ color: 'black' }}>Contact</Link>
            </li>
          </ul>
        </div>
        <div className="menu-right-item">
          <Link to={"/login"} className="menu-btn pt-2 pb-2">
            Log In / Sign Up
          </Link>
        </div>
      </div>
    </>
  );
}
