import {
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_ALL_USERS_FAILED,
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILED,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    FETCH_ALL_USERS_STATIC,
    FETCH_ALL_USERS_STATIC_SUCCESS,
    FETCH_ALL_USERS_STATIC_FAILED,
    USER_DELETED,
    FETCH_ALL_DRIVERS,
    FETCH_ALL_DRIVERS_SUCCESS,
    FETCH_ALL_DRIVERS_FAILED,
    FETCH_ADHAR_DATA,
    FETCH_FEEDBACK_DATA,
    FETCH_PAN_DATA,
    FETCH_RC_DATA,
    FETCH_LICENSE_DATA
    
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue, set, push, update, off, get, remove } from "firebase/database";
import {

    uploadBytesResumable,
    deleteObject,
    getDownloadURL

} from "firebase/storage";
import { signOut } from "firebase/auth";
import axios from "axios";
import CryptoJS from "crypto-js";
export const fetchUsers = () => (dispatch) => {

    const {
        usersRef,
        allLocationsRef
    } = firebase;
    dispatch({
        type: FETCH_ALL_DRIVERS,
        payload: null
    });
    onValue(usersRef, async snapshot => {
        if (snapshot.val()) {
            const locationdata = await get(allLocationsRef);
            const locations = locationdata.val();
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = parseInt(i);
                    data[i].location = locations && locations[i] ? locations[i] : null;
                    return data[i];
                });
            dispatch({
                type: FETCH_ALL_USERS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_ALL_USERS_FAILED,
                payload: "No users available."
            });
        }
    });
};

// fetch feedbacks
export const fetchFeedbacks = () => (dispatch) => {
    const {
        feedBackRef,
        usersRef
    } = firebase;

    // FETCH_FEEDBACK_DATA
    dispatch({
        type: FETCH_FEEDBACK_DATA,
        payload: null
    });
    onValue(feedBackRef, snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            console.log(data, "data")
            const arr = Object.keys(data)
                .map(i => {
                    // get user by id
                    // usersRef
                    onValue(usersRef, snapshot => {
                        if (snapshot.val()) {
                            const userdata = snapshot.val();
                            const userarr = Object.keys(userdata)
                                .filter(j => j == data[i].uid)
                                .map(j => {
                                    data[i].user = userdata[j];
                                    data[i].id = i;
                                    return data[i];
                                });
                            return userarr;
                        }
                    });
                    return data[i];
                });
            //   seperate user type driver and customer
            const driverArr = arr.filter(i =>i.user && i.user.usertype == "driver");
            const customerArr = arr.filter(i => i.user && i.user.usertype == "customer");
            dispatch({
                type: FETCH_FEEDBACK_DATA,
                payload:
                {
                    driverArr,
                    customerArr
                }
            });
        } else {
            dispatch({
                type: FETCH_FEEDBACK_DATA,
                payload: "No users available."
            });
        }
    });
};
// editFeedBack
export const editFeedBack = (id, data) => (dispatch) => {
    const {
        feedBackRefUpdate
    } = firebase;
    dispatch({
        type: EDIT_USER,
        payload: data
    });
    update(feedBackRefUpdate(id), data);
}

export const fetchUsersOnce = () => async (dispatch) => {
    const { usersRef, allLocationsRef } = firebase;

    dispatch({
        type: FETCH_ALL_USERS_STATIC,
        payload: null
    });

    try {
        const snapshot = await get(usersRef);

        if (snapshot.val()) {
            const locationData = await get(allLocationsRef);
            const locations = locationData.val();
            const data = snapshot.val();
            const arr = Object.keys(data).map((i) => {
                data[i].id = i;
                data[i].location = locations && locations[i] ? locations[i] : null;
                return data[i];
            });

            dispatch({
                type: FETCH_ALL_USERS_STATIC_SUCCESS,
                payload: arr
            });

            return arr;
        } else {
            dispatch({
                type: FETCH_ALL_USERS_STATIC_FAILED,
                payload: "No users available."
            });
            return []; // Return an empty array when no users are available
        }
    } catch (error) {
        // Handle any errors that occur during the fetch
        console.error(error);
        dispatch({
            type: FETCH_ALL_USERS_STATIC_FAILED,
            payload: "An error occurred while fetching users."
        });
        return []; // Return an empty array in case of an error
    }
};

export const clearFetchDrivers = () => (dispatch) => {
    const {
        driversRef,
        allLocationsRef,
    } = firebase;
    off(driversRef);
    off(allLocationsRef);
}

export const fetchDrivers = (appType) => async (dispatch) => {

    const {
        driversRef,
        allLocationsRef,
        settingsRef,
    } = firebase;

    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();
    dispatch({
        type: FETCH_ALL_USERS,
        payload: null
    });

    onValue(driversRef, snapshot => {

        if (snapshot.val()) {
            onValue(allLocationsRef, locres => {
                const locations = locres.val();
                const data = snapshot.val();
                const arr = Object.keys(data)
                    .filter(i => data && data[i].approved == true && data[i].driverActiveStatus == true && locations && locations[i] && ((data[i].licenseImage && settings.license_image_required) || !settings.license_image_required) &&
                        (((data[i].carApproved && settings.carType_required) || !settings.carType_required) || !settings.carType_required) && ((data[i].term && settings.term_required) || !settings.term_required))
                    .map(i => {
                        return {
                            id: i,
                            location: locations && locations[i] ? locations[i] : null,
                            carType: data[i].carType ? data[i].carType : null,
                            vehicleNumber: data[i].vehicleNumber ? data[i].vehicleNumber : null,
                            firstName: data[i].firstName,
                            lastName: data[i].lastName,
                            queue: data[i].queue
                        };
                    });
                dispatch({
                    type: FETCH_ALL_DRIVERS_SUCCESS,
                    payload: arr
                });
            }, appType === 'app' ? { onlyOnce: true } : settings && settings.realtime_drivers ? null : { onlyOnce: true })
        } else {
            dispatch({
                type: FETCH_ALL_DRIVERS_FAILED,
                payload: "No users available."
            });
        }
    }, appType === 'app' ? { onlyOnce: true } : settings && settings.realtime_drivers ? null : { onlyOnce: true });
};



export const addUser = (userdata) => async (dispatch) => {
    const {
        usersRef,
        carAddRef,
        carsRef,
        carTypesEditRef
    } = firebase;
    console.log(userdata, "userdata")
    dispatch({
        type: EDIT_USER,
        payload: userdata
    });

    delete userdata.tableData;

    push(usersRef, userdata).then(() => {
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });

    }).catch((error) => {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    });
    const carTypeSnapshot = await get(carTypesEditRef(userdata?.car_type));
    console.log(carTypeSnapshot.val(), "carTypeSnapshot.val()")
    await push(carAddRef, {
        carType: carTypeSnapshot.val().name,
        vehicleNumber: userdata?.vehicleNumber,
        driver: userdata?.driverId,
        createdAt: new Date().getTime(),
        vehicleMake: userdata?.vehicleMake,
        vehicleModel: userdata?.vehicleModel,
        other_info: userdata?.other_info,
        approved: userdata?.approved,

    })
}

// get user by email
export const getUserByEmail = (email) => async (dispatch) => {

    const {
        usersRef
    } = firebase;

    const snapshot = await get(usersRef);
    const data = snapshot.val();
    const arr = Object.keys(data)
        .filter(i => data[i].email === email)
        .map(i => {
            data[i].id = i;
            return data[i];
        });
    return arr[0];
}


export const editUser = (id, user) => async (dispatch) => {
    console.error(id, user, "id, user");

    const {
        singleUserRef,
        carsRef,
        carAddRef,
        carTypesEditRef,
        carsRefAll,
        carEditRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: user
    });
    let dId = id;
    let editedUser = user;
    delete editedUser.id;
    delete editedUser.tableData;
    set(singleUserRef(id), editedUser);

    console.log(editedUser, "editedUser")
    if (editedUser.usertype === 'driver') {
        let snapshot = await get(carsRefAll);
        var data = null;
        if (snapshot.val()) {
            // get where driver id is same
            data = snapshot.val();
            for (let i = 0; i < Object.keys(data).length; i++) {
                if (data[Object.keys(data)[i]].driver == editedUser.uid) {
                    // console.log(data[Object.keys(data)[i]], "data[Object.keys(data)[i]]")
                    // id
                    console.log(Object.keys(data)[i])
                    data.id = Object.keys(data)[i];
                    const carTypeSnapshot = await get(carTypesEditRef(editedUser?.car_type));
                    console.log(Object.keys(data)[i], "data.iddata.iddata.id")
                    // carEditRef
                    update(carEditRef(Object.keys(data)[i]), {
                        carType: carTypeSnapshot.val().name,
                        vehicleNumber: editedUser?.vehicleNumber,
                        driver: editedUser?.uid,
                        createdAt: new Date().getTime(),
                        vehicleMake: editedUser?.vehicleMake,
                        vehicleModel: editedUser?.vehicleModel,
                        other_info: editedUser?.other_info,
                        approved: editedUser?.approved,

                    })
                    data = data[Object.keys(data)[i]];

                   

                    const snapshot = await get(carsRef(dId, editedUser.usertype));
                    console.log(snapshot.val(), "snapshot.val()")

                }
                else{
                    data =[]
                }
            }
            console.log(data, "data")
        }
        console.log(data, "datadata)")
        if (data && data?.length>0) {
        }
        else {
            console.log("else")
            const carTypeSnapshot = await get(carTypesEditRef(editedUser?.car_type));
            await push(carAddRef, {
                carType: carTypeSnapshot.val().name,
                vehicleNumber: editedUser?.vehicleNumber,
                driver: editedUser?.uid,
                createdAt: new Date().getTime(),
                vehicleMake: editedUser?.vehicleMake,
                vehicleModel: editedUser?.vehicleModel,
                other_info: editedUser?.other_info,
                approved: editedUser?.approved,

            })
            const snapshot = await get(carsRef(dId, editedUser.usertype));
            console.log(snapshot.val(), "snapshot.val()")
        }
    }
}

export const updateUserCar = (id, data) => (dispatch) => {
    const {
        singleUserRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: data
    });
    update(singleUserRef(id), data);
}

export const updateLicenseImage = (uid, imageBlob, imageType) => async (dispatch) => {
    console.log(uid, imageBlob, imageType, "uid, imageBlob, imageType")
    const {
        singleUserRef,
        driverDocsRef,
        driverDocsRefBack,
        verifyIdImageRef,
        addressProofFrontImage,
        addressProofBackImage,
        profileImageRef,
        rcBackImageRef,
        rcFrontImageRef,
        cars,
        carEditRef,
        carsRefAll

    } = firebase;

    let profile = {};
    try {
        if (imageType === 'licenseImage') {
            await uploadBytesResumable(driverDocsRef(uid), imageBlob);
            let image = await getDownloadURL(driverDocsRef(uid));

            console.log(image, "image")
            profile.licenseImage = image;
        }
        if (imageType === 'licenseImageBack') {
            await uploadBytesResumable(driverDocsRefBack(uid), imageBlob);
            let image1 = await getDownloadURL(driverDocsRefBack(uid));
            profile.licenseImageBack = image1;
        }
        if (imageType === 'verifyIdImage') {
            await uploadBytesResumable(verifyIdImageRef(uid), imageBlob);
            let image1 = await getDownloadURL(verifyIdImageRef(uid));
            profile.verifyIdImage = image1;
        }
        if (imageType === 'addressProofFrontImage') {

            await uploadBytesResumable(addressProofFrontImage(uid), imageBlob);
            let image1 = await getDownloadURL(addressProofFrontImage(uid));
            profile.addressProofFrontImage = image1;
        }
        if (imageType === 'addressProofBackImage') {
            await uploadBytesResumable(addressProofBackImage(uid), imageBlob);
            let image1 = await getDownloadURL(addressProofBackImage(uid));
            profile.addressProofBackImage = image1;
        }
        if (imageType === 'profile_image') {
            await uploadBytesResumable(profileImageRef(uid), imageBlob);
            let image1 = await getDownloadURL(profileImageRef(uid));
            profile.profile_image = image1;
        }
        if (imageType === "rcFrontImage") {

            await uploadBytesResumable(rcFrontImageRef(uid), imageBlob);
            let image1 = await getDownloadURL(rcFrontImageRef(uid));
            profile.rcFrontImage = image1;
                //    get cars by driver id
        let snapshot = await get(carsRefAll);
        var data = null;
        // just add rcFrontImage in cars
        if (snapshot.val()&& image1) {
            // get where driver id is same
            data = snapshot.val();
            for (let i = 0; i < Object.keys(data).length; i++) {
                if (data[Object.keys(data)[i]].driver == uid) {
                    // console.log(data[Object.keys(data)[i]], "data[Object.keys(data)[i]]")
                    // id
                    console.log(Object.keys(data)[i])
                    data.id = Object.keys(data)[i];
                    // carEditRef
                    update(carEditRef(Object.keys(data)[i]), {
                        rcFrontImage: image1,
                    })
                    data = data[Object.keys(data)[i]];
                }
            }
            console.log(data, "data")
        }
        //    update in cars 
         
        

        }
        if (imageType === "rcBackImage") {
            await uploadBytesResumable(rcBackImageRef(uid), imageBlob);
            let image1 = await getDownloadURL(rcBackImageRef(uid));
            profile.rcBackImage = image1;
        }
        // Await the update function to ensure it has completed before dispatching
        await update(singleUserRef(uid), profile);

        dispatch({
            type: EDIT_USER,
            payload: uid
        });
    } catch (error) {
        console.error('Error updating license image:', error);
        // Handle the error appropriately, e.g., dispatch an error action
    }

};
// encrypt data in 256 formate
// Function to calculate SHA-256 hash
const getSHA256 = (data) => {

    // Data to be encrypted
    const dataToEncrypt = data;
    // AES encryption key (32 bytes for AES-256)
    const secretKey = 'DjmIrMAPgBjaHAgvANxWJufjjxGYthGs';
    // Encrypt the data
    const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, CryptoJS.enc.Utf8.parse(secretKey), {
        mode: CryptoJS.mode.ECB, // You can adjust the mode if needed
        keySize: 256
    });
    // Convert the encrypted data to Base64
    const base64EncryptedData = encryptedData.toString();
    return base64EncryptedData;
}
// decrypt data from 256 formate
function getSHA256Decrypted(data) {
    // Base64-encoded ciphertext
    const base64Ciphertext = data
    // AES-256 decryption key (32 bytes)
    const secretKey = 'DjmIrMAPgBjaHAgvANxWJufjjxGYthGs';
    try {
        // Step 1: Decode the Base64-encoded ciphertext
        const ciphertextBytes = CryptoJS.enc.Base64.parse(base64Ciphertext);
        // Step 2: Decrypt the decoded data using AES-256
        const decrypted = CryptoJS.AES.decrypt({
            ciphertext: ciphertextBytes
        }, CryptoJS.enc.Utf8.parse(secretKey), {
            mode: CryptoJS.mode.ECB, // You can adjust the mode if needed
            keySize: 256
        });
        // Convert the decrypted data to a UTF-8 string
        const plaintext = decrypted.toString(CryptoJS.enc.Utf8);
        return plaintext;
    } catch (error) {
        console.error('Decryption error:', error);
    }
}

// // pan verification
// export const panVerification = ({
//     source,
//     source_type,
//     uuid,
//     firstName
// }) => async (dispatch) => {
//     const {
//         singleUserRef
//     } = firebase;
//     console.log(source, source_type, "source, source_type")
//     let api_data = getSHA256(JSON.stringify({
//         "reference_id": "pan" + Math.floor(Math.random() * 100000),
//         "source_type": source_type,
//         "source": source
//     }));
//     let resData = {};
//     // promise
//     Promise.resolve(axios.post('https://kyc-uat.signdesk.in/api/sandbox/panVerification', {
//         "api_data": api_data,
//         "enc_mode": "symmetric",
//         "is_encrypted": true
//     }, {
//         headers: {
//             "Content-Type": "application/json",
//             "x-parse-application-id": "gobabygocabspvtltd_ekyc_live",
//             "x-parse-rest-api-key": "8a8cacddfa75cc5e62fae1484f94e22c",
//         }
//     })).then(async (res) => {
//         resData = res.data;
//         let decryptedData = getSHA256Decrypted(resData.encrypted_response);
//         // parse decrypted data which is plain text
//         console.log(JSON.parse(decryptedData), "JSON.parse(decryptedData)")
//         if (JSON.parse(decryptedData).status == "failed") {
//             alert(JSON.parse(decryptedData).error)
//         } else if (JSON.parse(decryptedData).status == "success") {
//             console.log(JSON.parse(decryptedData).result.validated_data.full_name.toLowerCase(), "lower")
//             console.log(firstName.toLowerCase(), "loweree")
//             let isValid = JSON.parse(decryptedData).result.validated_data.full_name.toLowerCase().indexOf(firstName.toLowerCase()) >= 0


//             update(singleUserRef(uuid), {
//                 valid_pan: JSON.parse(decryptedData).result.valid_pan,
//                 pan_name: JSON.parse(decryptedData).result.validated_data.full_name,
//             });

//             if (JSON.parse(decryptedData).result?.valid_pan) {
//                 alert(JSON.parse(decryptedData).result?.message)
//             }
//             else {
//                 alert("Pan Name and User Name not matched")
//             }

//         }
//     }).catch((error) => {
//         console.log(error, "error")
//         alert("Something went wrong")
//     });
//     return resData;

// };



// pan verification
export const panVerification = ({
    source,
    source_type,
    uuid,
    firstName
}) => async (dispatch) => {
    const { singleUserRef } = firebase;

    console.log(source, source_type, "source, source_type");

    const api_data = getSHA256(JSON.stringify({
        "reference_id": "pan" + Math.floor(Math.random() * 100000),
        "source_type": source_type,
        "source": source
    }));

    try {
        const res = await axios.post('https://kyc.signdesk.in/api/live/panVerification', {
            "api_data": api_data,
            "enc_mode": "symmetric",
            "is_encrypted": true
        }, {
            headers: {
                "Content-Type": "application/json",
                "x-parse-application-id": "gobabygocabspvtltd_ekyc_live",
                "x-parse-rest-api-key": "8a8cacddfa75cc5e62fae1484f94e22c",
            }
        });

        const decryptedData = getSHA256Decrypted(res.data.encrypted_response);
        const parsedData = JSON.parse(decryptedData);
        console.log(parsedData, "decryptedData");

        if (parsedData.status === "failed") {
            alert(parsedData.error);
            throw new Error(parsedData.error);
        }

        if (parsedData.status === "success") {
            let isValid = parsedData.result.validated_data.full_name.toLowerCase().indexOf(firstName.toLowerCase()) >= 0;

            update(singleUserRef(uuid), {
                valid_pan: parsedData.result.valid_pan,
                pan_name: parsedData.result.validated_data.full_name,
            });

            if (parsedData.result?.valid_pan) {
                //  FETCH_PAN_DATA
                dispatch({
                    type: FETCH_PAN_DATA,
                    payload: parsedData.result
                })
                
                alert(parsedData.result?.message);
            } else {
                alert("Pan Name and User Name not matched");
            }
        }

        return parsedData;
    } catch (error) {
        console.error("Error during PAN Verification:", error.message);
        alert("Something went wrong");
        throw error;
    }
};

// https://kyc-uat.signdesk.in/api/sandbox/voterIDVerificationrcVerification
function formateDate(date) {

    //date will be dd-mm-yyyy
    console.log(date, "date")
    let year = date.split("-")[2];
    let month = date.split("-")[1];
    let day = date.split("-")[0];
    return [year, month, day].join('-');

}

// // voterIDVerification
// export const licenseVerification = ({


//     source,
//     source_type,
//     dob,
//     uuid,
//     firstName
// }) => async (dispatch) => {
//     const {
//         singleUserRef
//     } = firebase;
//     // console.log(source, source_type,uuid, formateDate(dob), "source, source_type")
//     let api_data = getSHA256(JSON.stringify({
//         "reference_id": "license" + Math.floor(Math.random() * 100000),
//         "source_type": source_type,
//         "source": source,
//         "dob": formateDate(dob)
//     }));
//     if (!dob) {
//         alert("DOB Missing")
//         return
//     }
//     let resData = {};
//     // promise
//     Promise.resolve(axios.post('https://kyc-uat.signdesk.in/api/sandbox/drivingLicenseVerification', {
//         "api_data": api_data,
//         "enc_mode": "symmetric",
//         "is_encrypted": true
//     }, {
//         headers: {
//             "Content-Type": "application/json",
//             "x-parse-application-id": "gobabygocabspvtltd_ekyc_live",
//             "x-parse-rest-api-key": "8a8cacddfa75cc5e62fae1484f94e22c",
//         }
//     })).then(async (res) => {
//         resData = res.data;
//         let decryptedData = getSHA256Decrypted(resData.encrypted_response);
//         // parse decrypted data which is plain text
//         console.log(JSON.parse(decryptedData), "decryptedData")
//         if (!dob) {
//             alert("DOB Missing")
//             return;
//         }
//         if (JSON.parse(decryptedData).status == "failed") {
//             alert(JSON.parse(decryptedData).error)
//         } else if (!JSON.parse(decryptedData).result.valid_dl) {
//             alert(JSON.parse(decryptedData).result?.message)
//         } else if (JSON.parse(decryptedData)?.result?.valid_dl) {
//             // add in user database
//             console.log(JSON.parse(decryptedData), "decryptedData")
//             let isValid = JSON.parse(decryptedData).result.validated_data.name.indexOf(firstName) >= 0
//             update(singleUserRef(uuid), {
//                 valid_dl: JSON.parse(decryptedData).result.valid_dl,
//                 dl_name: JSON.parse(decryptedData).result.validated_data?.name,
//                 dl_state: JSON.parse(decryptedData).result.validated_data?.state,
//                 dl_permanent_address: JSON.parse(decryptedData).result.validated_data?.permanent_address,
//                 dl_permanent_zip: JSON.parse(decryptedData).result.validated_data?.permanent_zip,
//                 dl_father_or_husband_name: JSON.parse(decryptedData).result.validated_data?.father_or_husband_name,
//                 dl_dob: JSON.parse(decryptedData).result.validated_data?.dob,
//                 dl_doe: JSON.parse(decryptedData).result.validated_data?.doe,
//             });
//             if (JSON.parse(decryptedData).result?.valid_dl) {
//                 alert("License Verified")
//             }
//             else {
//                 alert("License Name and User Name not matched")
//             }

//         }
//     }).catch((error) => {
//         console.log(error, "error")
//         alert({
//             message: "Something went wrong",
//             error
//         })
//     });
//     return resData;

// }

// Lisence Verifivcation:
export const licenseVerification = ({
    source,
    source_type,
    dob,
    uuid,
    firstName
}) => async (dispatch) => {
    const { singleUserRef } = firebase;

    if (!dob) {
        alert("DOB Missing");
        return;
    }

    const reference_id = "license" + Math.floor(Math.random() * 100000);
    const api_data = getSHA256(JSON.stringify({
        reference_id,
        source_type,
        source,
        dob: formateDate(dob)
    }));

    try {
        const res = await axios.post('https://kyc.signdesk.in/api/live/drivingLicenseVerification',
            {
                api_data,
                enc_mode: "symmetric",
                is_encrypted: true
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "x-parse-application-id": "gobabygocabspvtltd_ekyc_ms_live",
                    "x-parse-rest-api-key": "10d494b0a60ebbf30ffc135ba8940501",
                }
            }
        );

        const decryptedData = getSHA256Decrypted(res.data.encrypted_response);
        const parsedData = JSON.parse(decryptedData);

        console.log(parsedData, "decryptedData");

        if (parsedData.status === "failed") {
            alert(parsedData.error);
            return;
        }

        const { result } = parsedData;

        if (!result.valid_dl) {
            alert(result?.message);
            return;
        }

        if (result?.valid_dl) {
            console.log(parsedData, "decryptedData");
            const isValid = result.validated_data.name.includes(firstName);
            const userData = {
                valid_dl: result.valid_dl,
                dl_name: result.validated_data?.name,
                dl_state: result.validated_data?.state,
                dl_permanent_address: result.validated_data?.permanent_address,
                dl_permanent_zip: result.validated_data?.permanent_zip,
                dl_father_or_husband_name: result.validated_data?.father_or_husband_name,
                dl_dob: result.validated_data?.dob,
                dl_doe: result.validated_data?.doe,
            };

            await update(singleUserRef(uuid), userData);

            if (result?.valid_dl) {
                dispatch({
                    type: FETCH_LICENSE_DATA,
                    payload: result
                });
                alert("License Verified");
            } else {
                alert("License Name and User Name not matched");
            }
        }

        return parsedData;

    } catch (error) {
        console.error("License verification error:", error.message);
        alert("Something went wrong");
    }
};





// rc verification
export const rcVerification = ({

    source,
    source_type,
    uuid,

}) => async (dispatch) => {
    const {
        singleUserRef
    } = firebase;
    console.log(source, source_type,uuid, "source, source_type")
    let api_data = getSHA256(JSON.stringify({
        "reference_id": "rc" + Math.floor(Math.random() * 100000),
        "source_type": source_type,
        "source": source
    }));
    let resData = {};
    // promise
    Promise.resolve(axios.post('https://in-rc-verify.signdesk.com/api/rcVerification', {
        "api_data": api_data,
        "enc_mode": "symmetric",
        "is_encrypted": true
    }, {
        headers: {
            "Content-Type": "application/json",
            "x-parse-application-id": "gobabygocabspvtltd_ekyc_live",
            "x-parse-rest-api-key": "8a8cacddfa75cc5e62fae1484f94e22c",
        }
    })).then(async (res) => {
        resData = res.data;
        let decryptedData = getSHA256Decrypted(resData.encrypted_response);
        console.log(JSON.parse(decryptedData), "decryptedData")

        // parse decrypted data which is plain text
        if (JSON.parse(decryptedData).status == "failed") {
            alert(JSON.parse(decryptedData).error)
        } else if (JSON.parse(decryptedData).status == "success") {
            // add in user database
            console.log(JSON.parse(decryptedData), "decryptedData")

            update(singleUserRef(uuid), {
                valid_rc: true,
                rc_registration_date: JSON.parse(decryptedData).result.validated_data.registration_date,
                rc_owner_name: JSON.parse(decryptedData).result.validated_data.owner_name,
                rc_father_name: JSON.parse(decryptedData).result.validated_data.father_name,
                rc_chasis_no: JSON.parse(decryptedData).result.validated_data.vehicle_chasi_number,
                rc_engine_co: JSON.parse(decryptedData).result.validated_data.vehicle_engine_number,
                rc_make: JSON.parse(decryptedData).result.validated_data.maker_description,
                rc_model: JSON.parse(decryptedData).result.validated_data.maker_model,
                rc_body_type: JSON.parse(decryptedData).result.validated_data.body_type,
                rc_fuel_type: JSON.parse(decryptedData).result.validated_data.fuel_type,
                rc_color: JSON.parse(decryptedData).result.validated_data.color,
                rc_engine_type: JSON.parse(decryptedData).result.validated_data.norms_type,
                rc_seat_capacity: JSON.parse(decryptedData).result.validated_data.seat_capacity,
                rc_pollution_no: JSON.parse(decryptedData).result.validated_data.pucc_number,
                rc_pollution_expiry: JSON.parse(decryptedData).result.validated_data.pucc_upto,
                rc_owner_number: JSON.parse(decryptedData).result.validated_data.owner_number,
                rc_status: JSON.parse(decryptedData).result.validated_data.rc_status,
                rc_insurance_company: JSON.parse(decryptedData).result.validated_data.insurance_company,
                rc_policy_no: JSON.parse(decryptedData).result.validated_data.insurance_policy_number,
                rc_insurance_upto: JSON.parse(decryptedData).result.validated_data.insurance_upto,
                rc_manufacturing_date: JSON.parse(decryptedData).result.validated_data.manufacturing_date,
            });

            if (JSON.parse(decryptedData).result?.valid_rc) {
                dispatch({
                    type: FETCH_RC_DATA,
                    payload: JSON.parse(decryptedData).result
                })
                alert("RC Verified")
            }
            else {
                alert("RC Verification Failed !")
            }

        }
    }).catch((error) => {
        console.log(error, "error")
        alert({
            message: "Something went wrong",
            error
        })
    });
    return resData;

};

// closeAlert
export const closeAlert = () => (dispatch) => {

    dispatch({
        type: FETCH_ADHAR_DATA,
        payload: {
            open: false
        }
    });
};

export const aadhaarXMLVerification = ({ source, source_type }) => async (dispatch) => {
    const { singleUserRef } = firebase;

    console.log(source, source_type, "source, source_type");

    let api_data = getSHA256(JSON.stringify({
        "reference_id": "aadhaar" + Math.floor(Math.random() * 100000),
        "source_type": source_type,
        "source": source
    }));

    try {
        const res = await axios.post('https://kyc.signdesk.in/api/live/aadhaarXMLVerification', {
            "api_data": api_data,
            "enc_mode": "symmetric",
            "is_encrypted": true
        }, {
            headers: {
                "Content-Type": "application/json",
                "x-parse-application-id": "gobabygocabspvtltd_ekyc_live",
                "x-parse-rest-api-key": "8a8cacddfa75cc5e62fae1484f94e22c",
            }
        });

        const decryptedData = getSHA256Decrypted(res.data.encrypted_response);
        const parsedData = JSON.parse(decryptedData);

        if (parsedData.status === "failed") {
            alert(parsedData.error);
            return Promise.reject(new Error(parsedData.error));
        } else if (parsedData.status === "success") {
            dispatch({
                type: FETCH_ADHAR_DATA,
                payload: {
                    data: parsedData,
                    open: true
                }
            });

            console.log(parsedData, "decryptedData");
            return parsedData;

        } else {
            console.log("Unexpected status in response data.");

            return Promise.reject(new Error("Unexpected status in response data."));
        }
    } catch (error) {
        console.error("Error during Aadhaar XML Verification:", error.message);
        alert("Something went wrong");
        return Promise.reject(error);
    }
};


export const aadhaarOTP = ({
    otp,
    reference_id,
    transaction_id,
    uuid,
    firstName
}) => async (dispatch) => {
    const { singleUserRef } = firebase;

    console.log(otp, reference_id, transaction_id, "source, source_type");

    let api_data = getSHA256(JSON.stringify({
        otp: otp,
        reference_id: reference_id,
        transaction_id: transaction_id
    }));

    try {
        const res = await axios.post('https://kyc.signdesk.in/api/live/aadhaarXMLSubmitOTP',
            {
                "api_data": api_data,
                "enc_mode": "symmetric",
                "is_encrypted": true
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "x-parse-application-id": "gobabygocabspvtltd_ekyc_live",
                    "x-parse-rest-api-key": "8a8cacddfa75cc5e62fae1484f94e22c",
                }
            }
        );

        const decryptedData = getSHA256Decrypted(res.data.encrypted_response);
        const parsedData = JSON.parse(decryptedData);

        console.log(parsedData, "decryptedData");

        if (parsedData.status === "failed") {
            alert(parsedData.message);
            throw new Error(parsedData.message);
        }

        if (parsedData.result.valid_aadhaar) {
            dispatch({
                type: FETCH_ADHAR_DATA,
                payload: {
                    data: parsedData,
                    open: true
                }
            });

            update(singleUserRef(uuid), {
                valid_aadhaar: JSON.parse(decryptedData).result.valid_aadhaar,
                aadhaar_full_name: JSON.parse(decryptedData).result.validated_data.full_name,
                aadhaar_dob: JSON.parse(decryptedData).result.validated_data?.dob,
                aadhaar_gender: JSON.parse(decryptedData).result.validated_data?.gender,
                aadhaar_country: JSON.parse(decryptedData).result.validated_data?.address?.country,
                aadhaar_dist: JSON.parse(decryptedData).result.validated_data?.address?.dist,
                aadhaar_state: JSON.parse(decryptedData).result.validated_data?.address?.state,
                aadhaar_po: JSON.parse(decryptedData).result.validated_data?.address?.po,
                aadhaar_loc: JSON.parse(decryptedData).result.validated_data?.address?.loc,
                aadhaar_vtc: JSON.parse(decryptedData).result.validated_data?.address?.vtc,
                aadhaar_subdist: JSON.parse(decryptedData).result.validated_data?.address?.subdist,
                aadhaar_street: JSON.parse(decryptedData).result.validated_data?.address?.street,
                aadhaar_house: JSON.parse(decryptedData).result.validated_data?.address?.house,
                aadhaar_landmark: JSON.parse(decryptedData).result.validated_data?.address?.landmark,
                aadhaar_zip: JSON.parse(decryptedData).result.validated_data?.zip,
            });

            dispatch({
                type: FETCH_ADHAR_DATA,
                payload: {
                    data: JSON.parse(decryptedData),
                    open: false
                }
            });

            console.log("Valid")
            if (JSON.parse(decryptedData).result.valid_aadhaar) {
                alert("Aadhaar Verified")
            } else {
                alert("Aadhaar Name and User Name not matched")

            }
        }

        return parsedData;
    } catch (error) {
        console.error("Error during Aadhaar OTP Verification:", error.message);
        alert("Something went wrong");
        throw error;
    }
};


// // adhaarOTP 
// export const aadhaarOTP = ({

//   otp,
//   reference_id,
//   transaction_id,
//   uuid,
//   firstName
// }) => async (dispatch) => {
//   const {
//       singleUserRef
//   } = firebase;
//   console.log(otp,
//       reference_id,
//       transaction_id, "source, source_type")
//   let api_data = getSHA256(JSON.stringify({
//       otp: otp,
//       reference_id: reference_id,
//       transaction_id: transaction_id
//   }));
//   let resData = {};
//   // promise
//   Promise.resolve(axios.post('https://kyc-uat.signdesk.in/api/sandbox/aadhaarXMLSubmitOTP',
//       {
//           "api_data": api_data,
//           "enc_mode": "symmetric",
//           "is_encrypted": true
//       }, {
//       headers: {
//           "Content-Type": "application/json",
//           "x-parse-application-id": "gobabygocabspvtltd_ekyc_live",
//           "x-parse-rest-api-key": "8a8cacddfa75cc5e62fae1484f94e22c",
//       }
//   })).then((res) => {
//       resData = res.data;
//       let decryptedData = getSHA256Decrypted(resData.encrypted_response);
//       // parse decrypted data which is plain text
//       console.log(JSON.parse(decryptedData), "decryptedData")
//       if (JSON.parse(decryptedData).status == "failed") {
//           alert(JSON.parse(decryptedData).message)
//       } else if (JSON.parse(decryptedData).result.valid_aadhaar) {
//           // FETCH_ADHAR_DATA
//           dispatch({
//               type: FETCH_ADHAR_DATA,
//               payload: {
//                   data: JSON.parse(decryptedData),
//                   open: true
//               }
//           });
//           // add in user databas
//           console.log(JSON.parse(decryptedData), "decryptedData")
//           // let isValid = JSON.parse(decryptedData).result.validated_data.full_name.indexOf(firstName) >= 0
//           update(singleUserRef(uuid), {
//               valid_aadhaar: JSON.parse(decryptedData).result.valid_aadhaar,
//               aadhaar_full_name: JSON.parse(decryptedData).result.validated_data.full_name,
//               aadhaar_dob: JSON.parse(decryptedData).result.validated_data?.dob,
//               aadhaar_gender: JSON.parse(decryptedData).result.validated_data?.gender,
//               aadhaar_country: JSON.parse(decryptedData).result.validated_data?.address?.country,
//               aadhaar_dist: JSON.parse(decryptedData).result.validated_data?.address?.dist,
//               aadhaar_state: JSON.parse(decryptedData).result.validated_data?.address?.state,
//               aadhaar_po: JSON.parse(decryptedData).result.validated_data?.address?.po,
//               aadhaar_loc: JSON.parse(decryptedData).result.validated_data?.address?.loc,
//               aadhaar_vtc: JSON.parse(decryptedData).result.validated_data?.address?.vtc,
//               aadhaar_subdist: JSON.parse(decryptedData).result.validated_data?.address?.subdist,
//               aadhaar_street: JSON.parse(decryptedData).result.validated_data?.address?.street,
//               aadhaar_house: JSON.parse(decryptedData).result.validated_data?.address?.house,
//               aadhaar_landmark: JSON.parse(decryptedData).result.validated_data?.address?.landmark,
//               aadhaar_zip: JSON.parse(decryptedData).result.validated_data?.zip,
//           });
//           // add input field and button for otp to call otp api
//           dispatch({
//               type: FETCH_ADHAR_DATA,
//               payload: {
//                   data: null,
//                   open: false
//               }
//           });
//           console.log("Valid")
//           if(JSON.parse(decryptedData).result.valid_aadhaar){
//           alert("Aadhaar Verified")
//           }else{
//               alert("Aadhaar Name and User Name not matched")
//           }
//       }
//       return resData;
//   }).catch((error) => {
//       console.log("error", error)
//       alert({
//           message: "Something went wrong",
//           error

//       })
//   });
//   return resData;

// };

export const deleteUser = (uid) => (dispatch) => {


    const {
        auth,
        walletHistoryRef,
        singleUserRef,
        userNotificationsRef,
        carsRef,
        carEditRef
    } = firebase;

    dispatch({
        type: DELETE_USER,
        payload: uid
    });

    if (auth.currentUser.uid === uid) {
        off(singleUserRef(uid));
        off(walletHistoryRef(uid));
        off(userNotificationsRef(uid));
    }

    onValue(singleUserRef(uid), userdata => {
        const profile = userdata.val();
        if (profile.usertype === 'driver') {
            onValue(carsRef(uid, profile.usertype), carssnapshot => {
                let cars = carssnapshot.val();
                if (cars) {
                    const arr = Object.keys(cars);
                    for (let i = 0; i < arr.length; i++) {
                        remove(carEditRef(arr[i]));
                    }
                }
            });
        }

        remove(singleUserRef(uid)).then(() => {
            if (auth.currentUser.uid === uid) {
                signOut(auth);
                dispatch({
                    type: USER_DELETED,
                    payload: null
                });
            } else {
                remove(singleUserRef(uid)).then(() => {
                    dispatch({
                        type: DELETE_USER_SUCCESS,
                        payload: null
                    });
                }).catch((error) => {
                    dispatch({
                        type: DELETE_USER_FAILED,
                        payload: error
                    });
                });
            }
        });
    }, { onlyOnce: true });

}

// collaboration
export const addCollaboration = (collaborationData) => async (dispatch) => {

    const {
        collaborationRef
    } = firebase;

    dispatch({
        type: EDIT_USER,
        payload: collaborationData
    });
    delete collaborationData.tableData;

    try {
        await push(collaborationRef, collaborationData);
        dispatch({
            type: EDIT_USER_SUCCESS,
            payload: null
        });
    } catch (error) {
        dispatch({
            type: EDIT_USER_FAILED,
            payload: error
        });
    }

}