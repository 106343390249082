import React, { useState, useEffect } from 'react';
import './Subscribe.css';
import { api } from 'common';
import { useSelector, useDispatch } from "react-redux";
import DriverProfileModal from './DriverProfileModal';

function DrivingPartner() {
    const { fetchUsers } = api;
    const bookings = useSelector(state => state.bookinglistdata);

    const usersdata = useSelector(state => state.usersdata);
    const [users, setUsers] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [profile, setProfile] = useState({});
    const [booking,setBooking] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);


    const handleSubscribe = () => {
        const subscribeValue = document.getElementById('subscribeValue').value;
        if (!subscribeValue) {
            return alert("Please enter driver id")
        }

        const driver = usersdata?.users.find(user => user.email === subscribeValue);
        const book = bookings?.bookings.find(booking => booking.driver == driver?.id);
    console.log(driver,"driver")
    console.log(book,"bookings?.bookings")
    console.log(bookings,"bookings?.bookings")
     
        setBooking(book);
        setProfile(driver);
        openModal()
    };
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
        //clear subscribe input
        document.getElementById('subscribeValue').value = ''; 
    };
    return (
        <div className="subscribe-section">
            <div className='inner-container'>
                <div className="subscribe-title">Know Your Driving Partner</div>
                <div className="subscribe-description">
                    First time in history now you can get to know about your ride partner (Driver). New initiative taken by GoBabyGo, you can get the information of your cab drivers here, with just one click. When you book your ride with GoBabyGo, you got a unique code of driving partner assigned to you. Copy that code and paste it here. A simplest way to know all mandatory details of your ride partner in just few seconds.!
                </div>
                <input type="email" placeholder="Please Enter Driver Id" className="subscribe-input"
                    id='subscribeValue'
                />
                <button className="subscribe-button"
                    onClick={handleSubscribe}
                >Search</button>

                <DriverProfileModal open={modalOpen} onClose={closeModal} profile={profile}  
                    customerImage={booking?.customer_image}
                    customerName={booking?.customer_name}
                    rating={booking?.rating}
                />
            </div>
        </div>
    );
}

export default DrivingPartner;
