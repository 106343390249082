import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header.js';
import HomeFooter from '../components/Footer/HomeFooter.js';
import { colors } from '../components/Theme/WebTheme';
import Hero from '../components/Hero';
import Section from '../components/Section';
import Download from '../components/Download';

import { useSelector } from 'react-redux'
import MainSafety from '../components/MainSafety.js';
import WhyChooseUs from '../components/WhyChooseUs.js';
import Testimonials from '../components/Testimonials.js';
import DrivingPartner from '../components/DrivingPartner.js';
import NoCommision from 'components/NoCommision.js';
import AboutOurCompany from 'components/AboutOurCompany.js';

const dashboardRoutes = [];

export default function LandingPage(props) {
  const auth = useSelector(state => state.auth);

  const { ...rest } = props;

  const [role, setRole] = useState();

  useEffect(() => {
    if (auth && auth.profile) {
      if (auth.profile.uid) {
        let role = auth.profile.usertype;
        setRole(role);
      }
    }
  }, [auth]);

  return (
    <div style={{ backgroundColor: colors.LandingPage_Background }}>
      {/* <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      /> */}
      <Header />
      <Hero role={role} />


      <AboutOurCompany />
      <NoCommision />
      <WhyChooseUs />
      <MainSafety />
      <Testimonials />
      <DrivingPartner />


      {/* <Section role={role} color={colors} /> */}
      {/* <Download /> */}
      <HomeFooter />
    </div>
  );
}
