import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function CEOMessage(props) {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const { ...rest } = props;

    return (
        <div style={{ margin: '-8px' }}>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: 'white',
                }}
                {...rest}
            />
            {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

            {/* Hero Section */}
            <div id="sidebox-overlay"></div>
            <section class="page-header">
                <div class="page-header-shape"></div>
                <div class="container">
                    <div class="page-header-info">
                        <h4>About Us!</h4>
                        <h2>
                            Feel your journey <br /> with <span>GoBabyGo!</span>
                        </h2>
                        <p>
                            Driving Innovation, Navigating the Future<br />
                            A Message from Our CEO{' '}
                        </p>
                    </div>
                </div>
            </section>

            <div style={{ margin: 20 }}>
                <div className={classes.container} style={{ padding: '5% 0' }}>
                    <h2>A message from CEO</h2>
                   
                    <p style={{ color: '#666666', padding: 0 }}>
                        An issue that unifies both of these worlds is the battle for dependable, inexpensive, and safe transportation, which strikes me as I stand at the intersection of urban sprawl and rural heartlands. Even though it has an impact on millions of people's lives, this issue frequently goes unreported. It's a problem that demands a response that's motivated not only by creativity but also by empathy and a deep knowledge of relationships between people.
                    </p>
                    
                    <p style={{ color: '#666666', padding: 0 }}>
                       This very understanding—that transportation is more than just traveling from point A to point B—led to the conception of GoBabyGoCabs. It's about the experiences that mold us, the narratives that unite us, and the aspirations that motivate us. It is about the hard-working father who provides for his family, the aspirational student who strives to do well in school, and the thrill-seeker who seeks the excitement of the unknown.
                    </p>
                   
                    <p style={{ color: '#666666', padding: 0 }}>
                        At the core of our vision lies a simple yet powerful motivation: happiness. Happiness is not only for our drivers who navigate the streets with dedication and skill but for everyone whose lives they touch. Every profession deserves respect, every effort deserves recognition, and every journey deserves to be a joyful one. With this in mind, we are committed to creating an ecosystem where drivers find not just better earnings, but also a sense of pride and fulfillment.
                    </p>
                    
                    <p style={{ color: '#666666', padding: 0 }}>
                       But our mission extends beyond the driver's seat. It embraces you, the rider – the heartbeat of our service. We believe that a ride should be more than just a transaction; it should be an experience marked by safety, reliability, and affordability. It should be a journey that leaves you not only at your destination but also with a smile on your face and a story to tell.
                    </p>
                    
                    <p style={{ color: '#666666', padding: 0 }}>
                        Innovation and technology are driving forces behind GoBabyGo Cabs.
                        Our user-friendly app provides drivers with real-time data,
                        efficient navigation tools, and insights to maximize their earnings.
                        We continuously invest in cutting-edge solutions to enhance the
                        driving experience, making it smoother, safer, and more rewarding.
                    </p>
                    
                    <p style={{ color: '#666666', padding: 0 }}>
                       Since this is much more than just a mode of transportation, I want you to accompany us as we journey together. Join a movement that encourages connection, uplifting, and empowerment. Because every journey, every driver, and every passenger matters, let's redefine our movements—not just physically, but also emotionally.
                    </p>
                    
                    <p style={{ color: '#666666', padding: 0 }}>
                       Thank you for choosing GoBabyGoCabs. Here's to the roads we travel, the dreams we chase, and the happiness we share.
                    </p>

                </div>

                {/* <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              position: 'relative',
              marginTop: '30px',
              minHeight: '32px',
              color: '#383838',
              textDecoration: 'none',
            }}
          >
            {t('about_us')}
          </h2>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Welcome to Gobabygo, the future of transportation! We are a
            revolutionary transportation startup that seeks to revolutionise how
            people travel by offering a cutting-edge app-based platform for
            customers to hail rides and drivers to conveniently charge fares and
            accept payments. Our fundamental objective is to build a sustainable
            ecology that is advantageous to both drivers and passengers and that
            is accessible and affordable to everyone.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            At gobabygo, we think that drivers should be empowered and given the
            chance to make some decent money. We have developed a mechanism that
            drastically lowers the commission costs paid by drivers, allowing
            them to keep a bigger share of their earnings in contrast to
            conventional ride-hailing platforms that charge high commissions. We
            hope to help them support their way of life and enhance their
            general quality of life by doing this.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            One of our key strategies for achieving sustainability and
            affordability is the implementation of a subscription-based model.
            This innovative approach enables passengers to enjoy the benefits of
            our services at a reduced cost. We aim to make sure that everyone,
            regardless of their financial situation, has access to safe and
            cheap transportation.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Our dedication to serving tier 2 and tier 3 cities and linking the
            entire country sets us apart from our rivals. We truly think that
            access to transport services should not just be restricted to
            metropolitan areas, but also extend to residents of smaller cities
            and villages. By extending our service to these areas, we hope to
            close the transportation gap and offer practical mobility solutions
            to previously underserved groups.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            We also take great pride in our commitment to environmental
            responsibility and sustainability. To help the environment become
            cleaner and greener and reduce carbon emissions, we actively urge
            drivers to choose eco-friendly vehicles. Our goal is to
            simultaneously improve the current transport system and make it more
            sustainable for coming generations.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Gobabygo is not just a transportation company; it is a movement
            towards a better future. We envision a world where transportation is
            efficient, affordable, sustainable, and accessible to all. Join us
            on this journey as we revolutionize the transportation industry, one
            ride at a time. Together, we can create a connected nation where no
            city is left behind.
          </p>
          <br />

  <ul class="about-info wow fade-in-right" data-wow-delay="400ms">
                            <li>
                                <img class="owner-thumb" src={comment1Img} alt="thumb" />
                                <div class="owner" style={{ marginLeft: '20px' }}>
                                    <h4>Founder - CEO</h4>
                                    <Button href='/about-us' target='_blank' style={{
                                        backgroundColor: '#f90', clipPath: 'polygon(0 0,100% 0%,90% 100%,0% 100%)', borderRadius: '2px', fontSize: '15px', height: '35px', padding: '0 23px 0 20px', color: 'white', marginTop: '10px'
                                    }}>Read More</Button>
                                </div>
                            </li>
                        </ul>


        </div> */}
            </div>

            <Footer />
        </div>
    );
}
