import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function TNC(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />

      

      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
         <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>TERMS OF USE</h2>
                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>This document constitutes an electronic record in accordance with the provisions of the Information Technology Act, 2000, and the corresponding rules enacted thereunder, as applicable. This record, generated by a computer system, is exempt from the requirement of physical or digital signatures.
                  <br /><br />
                 By accessing and using the GOBABYGO App and its services, you indicate your acceptance of and commitment to comply with the following terms and conditions, as well as the prevailing copyright policy. 
		<br /><br /> </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>1. General Information and Disclaimer:</strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <ul class="ul-li-tnc">
			<li >The content presented within the pages of the GOBABYGO App serves solely as general information and is subject to modifications without prior notification.</li>
			<li >We do not provide legal, professional, or advisory services. It is advised that you seek independent advice before acting on any information within the App. </li>
			<li >Your use of information or materials from the App is at your own risk. We disclaim liability and you are responsible for ensuring services or information align with your specific requirements. </li>
			<li >During the registration process on our platform, you may be required to submit your name, email address, and location. Additionally, we may collect data concerning your personal details, IP address, and App usage. This collected information may be employed to enhance the App or administer contests, promotions, surveys, or other features. </li>
			<li >The content presented within the pages of the GOBABYGO App serves solely as general information and is subject to modifications without prior notification.</li>
			<li >The content presented within the pages of the GOBABYGO App serves solely as general information and is subject to modifications without prior notification.</li>
		    </ul>
                  </p>
		<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>2. Limitation of Liability:</strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <ul class="ul-li-tnc">
			<li >We disclaim liability for any losses suffered through the use of the services, regardless of the cause, which includes but is not limited to negligence, breach of contract, copyright infringement, violation of other intellectual property rights, or defamation. We do not endorse nor assume liability for content published on the App by users or guest authors. In the event that any content appears inappropriate to us, we retain the right to remove such content.</li>
		   </ul>
                  </p>



		<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>3. Prohibited Activities:</strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <ul class="ul-li-tnc">
			<li >You are prohibited from publishing or causing to be published any content on the App that is unlawful, defamatory, obscene, offensive, harmful, or otherwise objectionable.</li>
			<li >Unauthorized use of automated tools to access or reproduce App content is strictly prohibited. Such usage is also prohibited if it aims to reproduce content or deliberately circumvent the App's navigational structure or alter its presentation.</li>
		<li >Commercial exploitation of the GOBABYGO App is not permissible without explicit permission from GOBABYGO. All rights not explicitly granted herein are reserved by GOBABYGO.</li>
		<li >Usage of the App or its content for unlawful purposes, solicitation of unlawful acts, violation of laws or regulations, infringement of intellectual property rights, harassment, abuse, insult, harm, defamation, submission of false information, uploading of malicious programs/viruses, engagement in obscene or immoral activities, or interference with security features is strictly prohibited. </li>
		<li >GOBABYGO retains the right to report instances of prohibited usage to relevant authorities.</li>
		<li >Any usage of the App that causes damage to the App or involves unlawful, harmful, or fraudulent actions may result in legal claims for damages or criminal charges.</li>

			</ul>
                  </p>


		<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>4. Privacy and Copyright:</strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <ul class="ul-li-tnc">
			<li >Personally identifiable information will not be sold or transferred, but non-personally identifiable data may be shared for marketing purposes. </li>
<li >App content is protected by copyright. Reproduction is prohibited without compliance with the copyright notice.</li>
<li >Periodically, the App may include links to external websites. These links are provided for informational convenience and do not constitute an endorsement by GOBABYGO. Linked third-party websites operate under distinct privacy policies, for which GOBABYGO bears no responsibility</li>

			</ul>
                  </p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>5. Dispute Resolution:</strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      With the exception of any other provisions outlined in these terms, these Terms shall be exclusively governed by and interpreted in accordance with the laws of India. Any dispute, conflict, claim, or controversy arising in connection with the Services or these Terms, encompassing matters related to its validity, construction, or enforceability (referred to as a "Dispute"), shall be initially submitted to mandatory mediation proceedings under Indian law. In the event that such Dispute remains unresolved for a period of sixty (60) days following the initiation of mediation proceedings as per Indian legal procedures, the matter may be referred to and shall ultimately be resolved solely through arbitration pursuant to the provisions of the Arbitration and Conciliation Act, 1996 ("Act"). The resolution of the Dispute shall be undertaken by a single arbitrator, appointed in accordance with the provisions of the Act. The venue for both mediation and arbitration shall be located in India. 
<br /><br />
The language employed in both the mediation and arbitration proceedings shall be English. The existence and contents of the mediation and arbitration processes, including documents and submissions presented by the parties, any correspondence from the mediator, as well as any correspondence, orders, and awards issued by the sole arbitrator, shall be kept in strict confidentiality. These details shall not be disclosed to any third party without express written consent from the opposing party, unless: (i) the disclosure to the third party is reasonably necessary for the progression of the mediation or arbitration proceedings, and (ii) the third party unconditionally agrees in writing to be bound by the confidentiality obligation outlined herein.
  
                  </p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>6. Severability:</strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    In the event any provision of these Terms of Use is determined to be unlawful, void, prohibited or unenforceable in any jurisdiction, such provisions shall be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions of these Terms of Use or affecting the validity or enforceability of such provisions in the same or any other jurisdiction.

                  </p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>7. Termination:</strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  At its sole discretion, GOBABYGO may modify or discontinue the App, or may modify or terminate your account/your access to this platform, for any reason, with or without notice to you and without liability to you or any third party.

                  </p>

 
          <br />
         </div>
      </div>

      <Footer />
    </div>
  );
}
