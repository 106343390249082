import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Copyright(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />

      

      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
         <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>COPYRIGHT POLICY</h2>
                  

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>Submission of Content </strong>
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                     We do not claim ownership of any Content that you submit, but to be able to legally provide the services to our visitors, we have to have certain rights to use such Content in connection with the site, as set forth below.<br /><br />

By providing any content to our platform in any form you agree to give us a ‘non-exclusive’ right and license over the content. Since the copyright and license is ‘non-exclusive’,
you are free to publish your content on other media/platforms too.<br /><br />

By providing any Content to our website: you agree to grant us a worldwide, royalty-free, perpetual, non-exclusive right and license (including any moral rights or other necessary rights) to use, display, reproduce, modify, adapt, publish, distribute, perform, promote, archive, translate, and to create derivative works and compilations, in whole or in part. Such license will apply with respect to any form, media, or technology known or later developed.<br /><br />

You warrant and represent that you have all legal, moral, and other rights that may be necessary to grant us the license set forth in this Section. <br /><br />

You acknowledge and agree that we shall have the right (but not obligation), in our sole discretion, to refuse to publish or to remove or block access to any content you provide at any time and for any reason, with or without notice.<br /><br />

No compensation will be paid with respect to the Content that you submit to GOBABYGO, unless otherwise agreed upon.
                  </p>
		  
  
          <br />
         </div>
      </div>

      <Footer />
    </div>
  );
}
