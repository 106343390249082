import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function OurCoreValues(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>Our Core Values:!</h4>
            <h2>
              Feel your journey <br /> with <span>GoBabyGo!</span>
            </h2>
            <p>
              Safe, Convenient, and Inclusive Transportation, <br />
              Every Time, Everywhere.{' '}
            </p>
          </div>
        </div>
      </section>

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
          <h2>Our Core Values:</h2>
          <h3>Empowerment:</h3>
          <p style={{ color: '#666666', padding: 0 }}>
           We believe in empowering drivers by eliminating commissions, enabling them to earn more for their hard work. By fostering a fair and transparent platform, we give drivers the opportunity to take control of their income and achieve financial stability. By providing them insurance we are helping them enjoy a tension-free life and Gobabygo will take your stand whenever the driver or their family needs any help.
          </p>
         <h3>Community-Centric: </h3>
<p style={{ color: '#666666', padding: 0 }}>
Our platform is built on the principle of fostering a strong community of drivers and riders. We prioritize the well-being and happiness of both drivers and riders, recognizing that their satisfaction is the cornerstone of our success. This sense of belonging creates an environment where everyone feels valued and supported.
</p>


<h3>Happiness and Trust: </h3>
<p style={{ color: '#666666', padding: 0 }}>
We are committed to spreading happiness – from the drivers and their families who benefit from increased earnings and to the riders who enjoy safe and affordable rides. Our dedication to building trust among all stakeholders ensures that every interaction on the platform is characterized by reliability, safety, and mutual respect. We care forthe drivers and their families, from caring after retirement to helping them in their difficult times and their children’s education.
</p>
<h3>Innovation with Purpose: </h3>
<p style={{ color: '#666666', padding: 0 }}>
We continuously strive to innovate the transportation industry by introducing new ideas and technologies. However, our innovation is purpose-driven. We are committed to enhancing the lives of drivers and riders, making their experiences more convenient, efficient, and enjoyable while maintaining the focus on our zero-commission approach.
</p>
        </div>

        {/* <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              position: 'relative',
              marginTop: '30px',
              minHeight: '32px',
              color: '#383838',
              textDecoration: 'none',
            }}
          >
            {t('about_us')}
          </h2>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Welcome to Gobabygo, the future of transportation! We are a
            revolutionary transportation startup that seeks to revolutionise how
            people travel by offering a cutting-edge app-based platform for
            customers to hail rides and drivers to conveniently charge fares and
            accept payments. Our fundamental objective is to build a sustainable
            ecology that is advantageous to both drivers and passengers and that
            is accessible and affordable to everyone.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            At gobabygo, we think that drivers should be empowered and given the
            chance to make some decent money. We have developed a mechanism that
            drastically lowers the commission costs paid by drivers, allowing
            them to keep a bigger share of their earnings in contrast to
            conventional ride-hailing platforms that charge high commissions. We
            hope to help them support their way of life and enhance their
            general quality of life by doing this.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            One of our key strategies for achieving sustainability and
            affordability is the implementation of a subscription-based model.
            This innovative approach enables passengers to enjoy the benefits of
            our services at a reduced cost. We aim to make sure that everyone,
            regardless of their financial situation, has access to safe and
            cheap transportation.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Our dedication to serving tier 2 and tier 3 cities and linking the
            entire country sets us apart from our rivals. We truly think that
            access to transport services should not just be restricted to
            metropolitan areas, but also extend to residents of smaller cities
            and villages. By extending our service to these areas, we hope to
            close the transportation gap and offer practical mobility solutions
            to previously underserved groups.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            We also take great pride in our commitment to environmental
            responsibility and sustainability. To help the environment become
            cleaner and greener and reduce carbon emissions, we actively urge
            drivers to choose eco-friendly vehicles. Our goal is to
            simultaneously improve the current transport system and make it more
            sustainable for coming generations.
          </p>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Gobabygo is not just a transportation company; it is a movement
            towards a better future. We envision a world where transportation is
            efficient, affordable, sustainable, and accessible to all. Join us
            on this journey as we revolutionize the transportation industry, one
            ride at a time. Together, we can create a connected nation where no
            city is left behind.
          </p>
          <br />
        </div> */}
      </div>

      <Footer />
    </div>
  );
}
