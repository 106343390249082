import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import riderPage from "../assets/img/rider-page.png"

// rider - page.jpg

function MainSafety() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{ backgroundColor: "#F5F5F5" }}>
            <Grid container spacing={3} style={{ width: '80%', margin: 'auto' }} >
                <Grid item xs={12} md={6} style={{ margin: 'auto' }}>
                    <h2 style={{ fontSize: '42px' }}>Safety with GoBabyGo!</h2>
                    <Typography variant="body1" style={{ marginTop: '3%' }}>
                        At GoBabyGo Cabs, we understand the importance of not only providing convenient rides but also prioritizing the safety of our riders as well as our drivers. With a commitment to safe travels, we have implemented a comprehensive set of measures to ensure the well-being of every individual who steps into our vehicles.
                    </Typography>
                    <Button href='/safety' style={{
                        backgroundColor: '#f90', clipPath: 'polygon(0 0,100% 0%,90% 100%,0% 100%)', borderRadius: '2px', fontSize: '15px', height: '35px', padding: '0 23px 0 20px', color: 'white', marginTop: '3%'
                    }}>Read More</Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    {isSmallScreen
                        ? <img src={riderPage} alt="riderPage" style={{ width: '100%' }} />
                        : <img src={riderPage} alt="riderPage" />
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default MainSafety;
