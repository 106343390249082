import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import styles from '../styles/staticPages.js';
import Parallax from 'components/Parallax/Parallax';
import { useTranslation } from 'react-i18next';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function RefundPolicy(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />

      

      {/* <Parallax
        small
        filter
        image={require('assets/img/header-back.jpg').default}
      /> */}

      {/* Hero Section */}
      <div id="sidebox-overlay"></div>
      

      <div style={{ margin: 20 }}>
        <div className={classes.container} style={{ padding: '5% 0' }}>
         <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>REFUND AND CANCELLATION POLICY</h2>
    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>               
This Refund and Cancellation Policy ("Policy") applies to the services provided by GOBABYGO ("Company"). By utilizing our services, you acknowledge and agree to adhere to the terms outlined in this Policy.
</p>
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>Refund Policy</strong>
                  </p>

 

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                     1. Refund Eligibility:
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                   Refunds will be considered in cases where there is an overcharge due to technical errors or discrepancies between the fare estimate and the actual fare charged for the service.

                  </p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    2. Requesting a Refund:
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  Clients seeking a refund for overcharges should initiate a refund request within 1 day from the completion of the ride. Requests must include ride details, fare amount, and supporting documentation.

                  </p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      3. Refund Review: 
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                   Each refund request will be carefully reviewed by our team. We reserve the right to evaluate requests on a case-by-case basis to determine whether they meet the predefined refund eligibility criteria.

                  </p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      4. Partial Refunds:
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  In certain situations, the Company may offer partial refunds based on the services rendered and the stage of completion. The decision to provide a partial refund will be made at the Company's discretion.

                  </p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      5. Refund Review: 
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  Each refund request will undergo a thorough review process. Refunds will only be issued if the overcharge is confirmed and verified by our team.

                  </p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      6. Refund Processing:
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                 Upon approval of a refund, the Company will initiate the refund process within 48 hours from the date of approval. The refunded amount will be credited back to the original payment method used for the transaction.

                  </p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      <strong>Cancellation Policy</strong>
                  </p>
 

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      1. Cancellation Eligibility:
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                 Cancellations can be made at any time prior to the driver's arrival at the pickup location. Once the driver is on the way or has arrived, cancellations are subject to cancellation charges.Registered riders/customers will be given three free chances to cancel the rides with valid reasons such as change of plans, pick-up time being too long, delay in arrival, booked by mistake, etc.

                  </p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                      2. Cancellation Charges:

                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                For every other cancellation by the user beyond the three free chances, a cancellation fee of Rs. 20 per cancellation shall be charged. No charges for Delayed pick-up. Users can cancel the ride without any cancellation charges if the driver is not picking them up within the stipulated time.

                  </p>



<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                     3. Cancellation Charges for En-Route Driver:
                  </p>

                  <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                If a cancellation is requested after the driver has been assigned and is en route to the pickup location, cancellation charges will apply.

                  </p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>No-Show Policy</strong></p>
  
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>1. No-Show Charges:</p>

<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
In the event that a rider fails to appear at the pickup location and doesn't communicate with the driver, a noshow charge will be applied to compensate the driver for their time and effort.
</p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>Policy Amendments</strong></p>
  
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
GOBABYGO reserves the right to revise and update this Refund and Cancellation Policy as needed. Any changes will be communicated through official channels. <br/>By using our services, you acknowledge that you have read, understood, and agreed to the terms of this Refund and Cancellation Policy.
</p>


<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>Contact Information</strong></p>
  
 
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
If you have any questions or concerns regarding this Policy, please contact our customer support team at - 9873115145 or email us at : <a href="mailto:info@gobabygocabs.com">info@gobabygocabs.com</a> 

 
</p>


<br/> <br/>
  
<p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}><strong>GOBABYGO (OPC) PRIVATE LIMITED</strong> 
<br/><br/>
<strong>Company's Operational Office Address</strong><br/>
E 58 3rd Floor, Sector-3 Noida  201301 <br/> <br/>

<strong>Company's Registered Office Address </strong><br/>
A-19, Ground Floor, FIEE COMPLEX,  <br/>
Okhla Phase-II, Okhla Industrial Estate Delhi-110020 <br/> <br/>
</p>


          <br />
         </div>
      </div>

      <Footer />
    </div>
  );
}
